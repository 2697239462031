/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    header-view module
    Authors     mac signer

*****************************************************************************/

.header-view {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;

	@include media-tablet() {
		align-items: center;
	}

	&__logo,
	&__menu {
		flex: 0 1 auto;
	}

	&__menu {
		margin-left: auto;
	}

	&__logo {
		.h2 {
			@include media-tablet() {
				font-size: 3rem;
				font-size: calc(1.6rem + 1vw);
			}
		}
	}
}
