/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    mixin clearfix
    Authors

*****************************************************************************/

@mixin clearfix() {
	&:after {
		content: ".";
		display: block;
		overflow: hidden;
		height: 0;
		clear: both;
		visibility: hidden;
		flex: 0 0 100%;
	}
}
