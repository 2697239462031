/**
 * Font-sizes
 */

$font-size-70: 1.6rem;
$font-size-80: 1.8rem;
$font-size-90: 2rem;
$font-size-100: 2.2rem;
$font-size-110: 3.5rem;
$font-size-120: 6.8rem;


/**
 * Font-weights
 */

$font-weight-regular: 400;
$font-weight-bold: 700;

/**
 * font families
 */

$font-family-main: "Source Sans Pro";
$font-family-title: "Ubuntu";

/**
 * Colors
 */

$black: #000;
$white: #fff;
$red: #C75947;
$red-light: lighten($red, 20%);
$red-dark: darken($red, 20%);
$orange: #CC9440;
$orange-light: lighten($orange, 20%);
$orange-dark: darken($orange, 20%);
$blue: #3B697F;
$blue-light: lighten($blue, 20%);
$blue-dark: darken($blue, 20%);

$green: #2A4C4A;
$green-light: lighten($green, 20%);
$green-dark: darken($green, 20%);

$grey: #21221C;

/**
 * Color Usage
 */

$selection-color: $white;
$selection-bg-color: rgba($green, .6);

$focus-outline-color: $green;

$theme-schemes:
	'.default' $green $white,
	'.color-scheme-black' $black $white,
;


/**
 * Measures
 */

$max-content-width: 120rem;
$content-margin-side: 2rem;

$base-distance: 3.4rem;
$link-box-distance: 2.4rem;

$gutter-width: 1.6rem;

/**
 * Breakpoints
 */

$breakpoint-max-content-width: $max-content-width / 1.6rem * 1em;
$breakpoint-desktop-small: 120em / 1.6;
$breakpoint-tablet: 90em / 1.6;
$breakpoint-mobile: 60em / 1.6;
