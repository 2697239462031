/**
 * Contao Module styles
 */

.ce_player {

	&__aspec {
		position: relative;
		height: 0;

		> * {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: auto !important;
			height: auto !important;
		}
	}

	mediaelementwrapper {
		display: block;
		height: 100%;
		width: 100%;
	}

	video {
		height: 100%;
		width: 100%;
	}

	button {
		background-color: transparent !important;
	}
}

.mejs {
	&__layer {
		width: 100% !important;
		height: 100% !important;
	}
}

.ce_gallery {
	@for $i from 2 through 6 {
		.cols_#{$i} {
			@include columns($i, $gutter-width);
		}
	}
}

.ce_accordion {
	.toggler {
		&:focus {
			outline: none;
		}
	}

	.accordion {
	}
}

/**
 * Custom classes
 */

.aspect {
	position: relative;
	height: 0;
	padding: 56.25% 0 0;

	> * {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
	}
}

@mixin icon-base() {
	display: inline-block;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	vertical-align: middle;
}

.icon {
	display: inline-block;
	vertical-align: middle;
}

.icon-stroke {
	@include color-schemes('stroke');
}

.icon-fill {
	@include color-schemes('fill');
}

.icon-plus {
	width: 3rem;
	height: 3rem;
	background-image: url(../img/icons/icon_plus.svg);

	@include icon-base();
}

.icon-close {
	display: inline-block;
	position: relative;
	width: 2.4rem;
	height: 2.4rem;

	@include media-tablet() {
		width: 2rem;
		height: 2rem;
	}

	&:before,
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 2px;

		@include color-schemes('background-color');
	}

	&:before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}

.icon-magnifying-glass {
	width: 1.8rem;
	height: 1.9rem;
	background-image: url(../img/icons/icon_magnifying_glass.svg);

	@include icon-base();
}

@mixin icon-hamburger($width: 4rem, $height: 2.6rem, $stroke: 2px, $color: $white) {
	display: inline-block;
	position: relative;
	width: $width;
	height: $height;
	border: $stroke solid $color;
	border-width: $stroke 0;

	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
		height: $stroke;
		background-color: $color;
	}
}

.icon-hamburger {
	@include icon-hamburger();
}

.desktop-small-hide {
	@include media-desktop-small() {
		display: none;
	}
}

.desktop-small-only {
	display: none;

	@include media-desktop-small() {
		display: block;
	}
}

.tablet-hide {
	@include media-tablet() {
		display: none;
	}
}

.tablet-only {
	display: none;

	@include media-tablet() {
		display: block;
	}
}

.sticky-map {
	@media screen and (min-height:#{80em / 1.6}) {
		position: sticky;
		top: 9rem;
		z-index: 10;
		background-color: $white;
		background-color: rgba($white, .8);
		transition: top .4s;
	}
}

.link-boxes {
	> ul {
		margin: 0 -#{$gutter-width / 2} $base-distance;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;

		@include media-mobile() {
			display: block;
		}

		> * {
			padding: $gutter-width / 2;
			width: 25%;
			-webkit-box-flex: 0;
			    -ms-flex: 0 0 auto;
			        flex: 0 0 auto;

			@include media-desktop-small() {
				width: 50%;
			}

			@include media-mobile() {
				width: auto;
			}
		}
	}
}

.image-map {
	@include media-desktop-small(){
		display: none;
	}

	img,
	canvas {
		margin-top: $base-distance;
// 		margin-bottom: $base-distance * 2;
		margin-bottom: 1rem;
	}

	canvas {
		opacity: .2;

		&.state-default {
		}

		&.state-hover {
			opacity: .8;
		}

		&.state-click {
			opacity: 1;
		}
	}
}

.apartments {
	> :last-child {
		margin-bottom: $base-distance * 3;
	}

	.caption {
		@include font-apartment-caption();
	}

	tr:hover,
	tr.hover,
	tr.click {
		.icon-stroke {
			@include color-schemes('', 'stroke');
		}

		.icon-fill {
			@include color-schemes('', 'fill');
		}
	}

	tr {
		a:hover {
			background-color: transparent;
		}

		&.status-sold,
		&.status-rented,
		&.status-reserved {
			@include schemes-transparent('background-color', .3);

			&:hover {
				@include color-schemes('color');
			}
		}
	}

	@include media-tablet() {
		table,
		thead,
		tbody,
		tr,
		td,
		th {
			display: block;
		}

		thead {
			display: none;
		}

		tr {
			padding: 1rem;

			@include clearfix();

			@include columns(3, $gutter-width, "&");
		}

		td {
			padding: .2rem 1rem .6rem;

			&:before {
				content: attr(data-title);
				display: block;
				margin-left: -1rem;

				@include font-apartment-caption();
			}
		}
	}

	@include media-mobile() {
		tr {
			@include columns(2, $gutter-width, "&");
		}
	}
}

.slick-slider{
	img{
		width: 100%;
	}

	a {
		outline: 0;
	}
	.slick-slide, .slick-slide * {
		outline: none;
	}
}

.article_gallery {
    overflow: hidden;
}

.cookie-bar {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	color: white;
	background-color: $grey;
	font-size: 2rem;

	p {
		margin-bottom: 1rem;
	}

	.mod_cookie_bar {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.cookie-button {
		padding: .5rem 1rem;
		border: 2px solid white;
		color: inherit;
		transition: background-color .2s, color .1s;

		&:hover {
			color: $grey;
			background-color: white;
			transition: background-color .4s, color .3s;
		}
	}
}

/**
 * Plugin overwrites
 */

.rs-column {
	> h3:first-child {
		margin-top: 0;
	}
}

.featherlight {
	.featherlight-content {
	}

	.featherlight-close-icon {
		background-color: transparent;
	}

	&:last-of-type {
		@include schemes-transparent('background-color');
	}
}

.featherlight-single-image {
	@supports (object-fit: contain) {
		.featherlight-content {
			margin-right: 25px;
			margin-left: 25px;
			padding: 0;
			border-width: 0;

			img {
				width: auto !important;
				height: auto !important;
				max-width: 80vw;
				max-width: calc( 100vw - 100px );
				max-height: 80vh;
				max-height: calc( 100vh - 100px );

				@include media-tablet() {
					max-width: calc( 100vw - 50px );
					max-height: calc( 100vh - 50px );
				}
			}
		}
	}

	img {
		border: 2.6rem solid $white;

		@include media-tablet() {
			border-width: 2rem;
		}
	}
}

.dlh_googlemap {
    height: 500px;
    width: 100%;
}
