/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    button module
    Authors     mac signer

*****************************************************************************/

/*
.button

:hover - hover

Markup:
	<a href="#" class="button {{modifier_class}}">Lorem</a>

Styleguide 100.2
*/

.button {
	display: inline-block;
	position: relative;
	max-width: 100%;
	padding: .6em 1em .4em;
	background-color: $green;
	color: $white;
	text-decoration: none;
	text-align: center;
	transition: color .3s, background .3s;
	border: 1px solid transparent;

	@include color-schemes('background-color', 'color');
	@include color-schemes('', 'border-color');

	&:hover {
		@include color-schemes('color', 'background-color');
	}

	&--invert {
		@include color-schemes('color', 'border-color');
		@include color-schemes('', 'background-color');

		&:hover {
			@include color-schemes('background-color', 'color');
		}
	}

	&--align-left {
		text-align: left;
	}

	&--align-right {
		text-align: right;
	}

	&--uppercase {
		text-transform: uppercase;
	}

	&--underline {
		text-decoration: underline;
	}

	&--block {
		display: block;
		width: auto;
	}

	&--large {
		font-size: 1.3em;
	}

	&--small {
		font-size: .8em;
	}
}
