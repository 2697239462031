// call to get specific schemes.
@mixin color-schemes($properties: '', $accent-properties: '', $upper-root: '') {
	@if( & != null ) {
		@each $scheme in $theme-schemes {
			$scheme-name: nth($scheme, 1);

			@if ( $scheme-name == ".default" ) {
				$scheme-name: "";
			}

			#{$upper-root}#{$scheme-name} & {
				@each $property in $properties {
					@if ($property != '') {
						#{$property}: #{nth($scheme, 2)};
					}
				}
				@each $accent-property in $accent-properties {
					@if ($accent-property != '') {
						#{$accent-properties}: #{nth($scheme, 3)}#{";"}
					}
				}
			}
		}
	} @else {
		@each $scheme in $theme-schemes {
			$scheme-name: nth($scheme, 1);

			@if ( $scheme-name == ".default" ) {
				$scheme-name: "body";
			}

			#{$upper-root}#{$scheme-name} {
				@each $property in $properties {
					@if ($property != '') {
						#{$property}: #{nth($scheme, 2)};
					}
				}
				@each $accent-property in $accent-properties {
					@if ($accent-property != '') {
						#{$accent-properties}: #{nth($scheme, 3)};
					}
				}
			}
		}
	}
}

@mixin schemes-transparent($properties: 'color', $opacity: .4) {
	@each $scheme in $theme-schemes {
		#{nth($scheme, 1)} & {
			@each $property in $properties {
				@if ($property != '') {
					#{$property}: rgba(nth($scheme, 2), $opacity);
				}
			}
		}
	}
}
