/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    to-top-view module
    Authors     mac signer

*****************************************************************************/

/*
.to-top-view

Styleguide classes.to-top-view
*/

.to-top-view {
	position: absolute;
	right: 0;
	bottom: 100%;
	left: 0;

	&__item {
		position: absolute;
		bottom: 0;
		right: 0;
		margin-bottom: 1.7rem;
	}
}
