/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    variable for mmenu overwrite
    Authors     mac signer
    Datum       14.05.18

*****************************************************************************/

$mmenu-border-color: $blue;
$mmenu-text-color: $blue-dark;
$mmenu-icon-color: $blue;
$mmenu-active-color: rgba($blue, .4);
$mmenu-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
$mmenu-background-color: $white;
$mmenu-checked-color: $blue-light;
