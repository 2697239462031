/* **************************************************************************
 *	MEDIA MOTION AG																												 *
 ****************************************************************************

		Funktion		rocksolid columns overwrite
		Authors
		Datum

*****************************************************************************/


@mixin column-classes($gutter-width, $max-columns, $class-name) {
	@for $cols from 1 through $max-columns {
		$colWidth: (1 - $gutter-width * ($cols - 1)) / $cols;

		@for $span from 1 through $cols {
			&.-#{unquote($class-name)}-col-#{$cols}-#{$span} {
				width: calc(
					(
						(
							100% - #{$gutter-width} * ( #{$cols - 1} )
						) / #{$cols} * (
							#{$span}
						) + #{$gutter-width} * #{$span - 1}
					) - 0.01px
				);
			}
		}
	}
}

.rs-columns {
	clear: both;
	width: 100%;
	display: block;

	@include clearfix();
}

.rs-column {
	float: left;
	width: 100%;
	min-height: 1px;
	margin-right: $gutter-width;

	&.-large-first {
		clear: left;
	}

	&.-large-last {
		margin-right: 0;
	}

	&.-large-first-row {
		margin-top: 0;
	}

	@include column-classes($gutter-width, 6, large);

	// Tablet portrait format (viewport width 900px and below)
	@include media-tablet() {

		margin-top: $gutter-width;
		margin-right: $gutter-width;

		&.-large-first {
			clear: none;
		}

		&.-large-last {
			margin-right: $gutter-width;
		}

		&.-large-first-row {
			margin-top: $gutter-width;
		}

		&.-medium-first {
			clear: left;
		}

		&.-medium-last {
			margin-right: 0;
		}

		&.-medium-first-row {
			margin-top: 0;
		}

		@include column-classes($gutter-width, 6, medium);
	}

	// Mobile (viewport width 599px and below)
	@include media-mobile() {

		margin-right: $gutter-width;

		&.-medium-first {
			clear: none;
		}

		&.-medium-last {
			margin-right: $gutter-width;
		}

		&.-medium-first-row {
			margin-top: $gutter-width;
		}

		&.-small-first {
			clear: left;
		}

		&.-small-last {
			margin-right: 0;
		}

		&.-small-first-row {
			margin-top: 0;
		}

		@include column-classes($gutter-width, 6, small);
	}
}
