/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    font includes
    Authors     mac signer
    Datum       10.05.18

*****************************************************************************/

/* ubuntu-regular - latin */
@font-face {
  font-family: $font-family-title;
  font-style: normal;
  font-weight: $font-weight-regular;
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
       url('../fonts/ubuntu-v13-latin-regular.woff2') format('woff2'),
       url('../fonts/ubuntu-v13-latin-regular.woff') format('woff');
}
/* ubuntu-italic - latin */
@font-face {
  font-family: $font-family-title;
  font-style: italic;
  font-weight: $font-weight-regular;
  src: local('Ubuntu Italic'), local('Ubuntu-Italic'),
       url('../fonts/ubuntu-v13-latin-italic.woff2') format('woff2'),
       url('../fonts/ubuntu-v13-latin-italic.woff') format('woff');
}
/* ubuntu-700 - latin */
@font-face {
  font-family: $font-family-title;
  font-style: normal;
  font-weight: $font-weight-bold;
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
       url('../fonts/ubuntu-v13-latin-700.woff2') format('woff2'),
       url('../fonts/ubuntu-v13-latin-700.woff') format('woff');
}
/* ubuntu-700italic - latin */
@font-face {
  font-family: $font-family-title;
  font-style: italic;
  font-weight: $font-weight-bold;
  src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'),
       url('../fonts/ubuntu-v13-latin-700italic.woff2') format('woff2'),
       url('../fonts/ubuntu-v13-latin-700italic.woff') format('woff');
}


/* source-sans-pro-300 - latin */
@font-face {
  font-family: $font-family-main;
  font-style: normal;
  font-weight: $font-weight-regular;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
       url('../fonts/source-sans-pro-v12-latin-300.woff2') format('woff2'),
       url('../fonts/source-sans-pro-v12-latin-300.woff') format('woff');
}
/* source-sans-pro-300italic - latin */
@font-face {
  font-family: $font-family-main;
  font-style: italic;
  font-weight: $font-weight-regular;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
       url('../fonts/source-sans-pro-v12-latin-300italic.woff2') format('woff2'),
       url('../fonts/source-sans-pro-v12-latin-300italic.woff') format('woff');
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: $font-family-main;
  font-style: normal;
  font-weight: $font-weight-bold;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url('../fonts/source-sans-pro-v12-latin-regular.woff2') format('woff2'),
       url('../fonts/source-sans-pro-v12-latin-regular.woff') format('woff');
}
/* source-sans-pro-italic - latin */
@font-face {
  font-family: $font-family-main;
  font-style: italic;
  font-weight: $font-weight-bold;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
       url('../fonts/source-sans-pro-v12-latin-italic.woff2') format('woff2'),
       url('../fonts/source-sans-pro-v12-latin-italic.woff') format('woff');
}
