/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    float module
    Authors

*****************************************************************************/

.float_left,
.float_right {
	margin: 0 2em 1em;
}

.float_left {
	float: left;
	margin-left: 0;
}

.float_right {
	float: right;
	margin-right: 0;
}

.float_left,
.float_right {
	@include media-tablet() {
		max-width: none;
		float: none;
		clear: both;
		margin: 2em 0;
	}
}
