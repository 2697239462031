/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    mmenu modoule
    Authors

*****************************************************************************/


$mmenu-border-color: $black !default;
$mmenu-text-color: $black !default;
$mmenu-icon-color: $black !default;
$mmenu-active-color: $blue-transparent !default;
$mmenu-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !default;
$mmenu-background-color: $white !default;
$mmenu-checked-color: $grey-light !default;

.mm-menu {
	&.mm-theme-white {
		background: $mmenu-background-color;
		border-color: $mmenu-border-color;
		color: $mmenu-text-color;

		.mm-navbar {
			a, > * {
				color: $mmenu-icon-color;
			}
		}

		.mm-btn {
			&:after, &:before {
				border-color: $mmenu-icon-color;
			}
		}

		.mm-listview {
			border-color: $mmenu-border-color;

			> li {
				.mm-next:after {
					border-color: $mmenu-icon-color;
				}

				a:not(.mm-next) {
					-webkit-tap-highlight-color: $mmenu-active-color;
					tap-highlight-color: $mmenu-active-color;
				}

				&.mm-selected > {
					a:not(.mm-next), span {
						background: $mmenu-active-color;
					}
				}

				&.mm-opened.mm-vertical > {
					.mm-panel, a.mm-next {
						background: $mmenu-active-color;
					}
				}
			}
		}

		&.mm-vertical .mm-listview > li.mm-opened > {
			.mm-panel, a.mm-next {
				background: $mmenu-active-color;
			}
		}

		.mm-divider {
			background: $mmenu-active-color;
		}

		label.mm-check:before {
			border-color: $mmenu-text-color;
		}

		em.mm-counter {
			color: $mmenu-icon-color;
		}

		.mm-fixeddivider span {
			background: $mmenu-active-color;
		}
	}

	&.mm-keyboardfocus a:focus {
		background: $mmenu-active-color;
	}

	&.mm-shadow-page.mm-theme-white:after, &.mm-shadow-panels.mm-theme-white .mm-panel.mm-opened:nth-child(n+2) {
		box-shadow: $mmenu-shadow;
	}

	&.mm-theme-white {
		.mm-search input {
			background: $mmenu-active-color;
			color: $mmenu-text-color;
		}

		.mm-noresultsmsg, .mm-indexer a {
			color: $mmenu-icon-color;
		}
	}
	&.mm-hoverselected.mm-theme-white .mm-listview > li > a {
		&.mm-fullsubopen:hover + span, &:not(.mm-fullsubopen):hover {
			background: $mmenu-active-color;
		}
	}

	&.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected {
		&.mm-fullsubopen + {
			a,
			span {
				background: $mmenu-active-color;
			}
		}
		&:not(.mm-fullsubopen) {
			background: $mmenu-active-color;
		}
	}

	&.mm-theme-white {
		label.mm-toggle {
			background: $mmenu-border-color;
			&:before {
				background: $mmenu-background-color;
			}
		}

		input.mm-toggle:checked ~ label.mm-toggle {
			background: $mmenu-checked-color;
		}
	}
}
