/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    icon font settings
    Authors     mac signer

*****************************************************************************/

$icomoon-font-family: "Ringgishalde";
$icomoon-font-path: "../libs/icon-font/fonts";
