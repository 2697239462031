/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    Rocksolid Grid alternative with custom gutter width
    Authors
    Datum

*****************************************************************************/


$row-margin-top: $gutter-width;

// mixin is depending on $gutter-width, c&p to mixin.scss if rocksolid-columns is not needed but need kind of a grid anyway
@mixin columns-scheme($columns, $gutter: $gutter-width, $class-basename: null) {
	$total-columns: 0;
	$total-items: 0;
	$columns-suffix: "";

	@each $column in $columns {
		$total-columns: $total-columns + $column;
		$total-items: $total-items + 1;
		$columns-suffix: "#{$columns-suffix}-#{$column}"
	}

	@if($class-basename) {
		$class-basename: "#{$class-basename}#{$columns-suffix}";
	} @else {
		$class-basename: "&";
	}

	$number-of-gutters: $total-columns - 1;

	#{$class-basename} {
		> * {
			float: left;

			@for $i from 1 through length($columns) {
				&:nth-child(#{$total-items}n+#{$i}) {
					width: calc( ( 100% - ( #{$number-of-gutters} * #{$gutter} ) ) / #{$total-columns} * #{nth($columns, $i)} + #{nth($columns, $i) - 1} * #{$gutter} - 0.01px);
					margin-right: calc( #{$gutter} );
					margin-bottom: $gutter;
					clear: none;
				}
			}

			&:nth-child(#{$total-items}n+1) {
				clear: left;
			}

			&:nth-child(#{$total-items}n+#{$total-items}) {
				margin-right: 0;
			}
		}

		@for $i from 1 through $total-items {
			&:nth-child(#{$i}) {
				margin-top: 0;
			}
		}

		@include clearfix();
	}
}

@mixin generate-basic-columns-scheme($class-basename){
	$total-columns: 12;

	@for $i from 1 through $total-columns {
		$first-span: $total-columns - $i;
		$second-span: $total-columns - $first-span;

		@if($i < $total-columns) {
			@include columns-scheme($first-span $second-span, $gutter-width, $class-basename);
		}
	}
}

// commented out because predefined class names would inflate the compiled stylesheet
/*
@include columns-scheme(4 2 3, 0px, '.columns');

.columns-scheme {
	@include columns-scheme(7 5 3, 0px);
}
*/

// mixin is depending on $gutter-width, c&p to mixin.scss if rocksolid-columns is not needed but need kind of a grid anyway
@mixin columns($columns, $gutter: $gutter-width, $class-basename: null) {
	$number-of-gutters: $columns - 1;

	@if($class-basename) {
		$class-basename: "#{$class-basename}-#{$columns}";
	} @else {
		$class-basename: "&";
	}

	#{$class-basename} {
		> * {
			float: left;

			&:nth-of-type(1n) {
				width: calc( ( 100% - ( #{$number-of-gutters} * #{$gutter} ) ) / #{$columns} - 0.01px);
				margin-right: calc( #{$gutter} );
				margin-bottom: $gutter;
				clear: none;
			}

			&:nth-of-type(#{$columns}n+1) {
				clear: left;
			}

			&:nth-of-type(#{$columns}n+#{$columns}) {
				margin-right: 0;
			}
		}

		@for $i from 1 through $columns {
			&:nth-of-type(#{$i}) {
				margin-top: 0;
			}
		}

		@include clearfix;
	}
}

// mixin is depending on $gutter-width, c&p to mixin.scss if rocksolid-columns is not needed but need kind of a grid anyway
@mixin push($columns-to-push, $push-origin: "left", $class-basename: null, $gutter: $gutter-width, $exclude-last-gutter: false, $columns-amount: 12) {
	$number-of-gutters: $columns-to-push;
	$total-number-of-gutters: $columns-amount - 1;

	@if($exclude-last-gutter) {
		$number-of-gutters: $number-of-gutters - 1;
	}


	@if($class-basename) {
		$class-basename: "#{$class-basename}-#{$columns-to-push}";
	} @else {
		$class-basename: "&";
	}

	#{$class-basename} {
		margin-#{$push-origin}: calc( ( 100% - #{$total-number-of-gutters} * #{$gutter} ) / #{$columns-amount} * #{$columns-to-push} + #{$number-of-gutters} * #{$gutter} );
	}
}

@for $i from 1 through 6 {
	@include columns($i, $gutter-width, '.columns');

}

@for $i from 0 through 11 {
	@include push($i, 'left', '.push-left');
	@include push($i, 'right', '.push-right');
}

@include generate-basic-columns-scheme('.columns');

@include media-tablet() {
	@for $i from 1 through 6 {
		@include columns($i, $gutter-width, '.columns-medium');
	}

	@for $i from 0 through 11 {
		@include push($i, 'left', '.push-left-medium');
		@include push($i, 'right', '.push-right-medium');
	}

	@include generate-basic-columns-scheme('.columns-medium');
}

@include media-mobile() {
	@for $i from 1 through 6 {
		@include columns($i, $gutter-width, '.columns-small');
	}

	@for $i from 0 through 11 {
		@include push($i, 'left', '.push-left-small');
		@include push($i, 'right', '.push-right-small');
	}

	@include generate-basic-columns-scheme('.columns-small');
}
