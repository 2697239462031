/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    animations
    Authors     mac signer
    Datum       14.05.18

*****************************************************************************/

@keyframes fadeInDown {
	0%,30% {
		opacity: 0;
		transform: translateY(-100px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes fadeInUp {
	0%,30% {
		opacity: 0;
		transform: translateY(100px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes fadeInRight {
	0%,30% {
		opacity: 0;
		transform: translateX(-100px);
	}
	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

