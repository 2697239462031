/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    icon-to-top module
    Authors     mac signer

*****************************************************************************/

/*
.icon-to-top

Styleguide classes.icon-to-top
*/

.icon-to-top {
	display: inline-flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 1em;
	height: 1em;
	font-size: 1.4em;
	text-align: center;
	background-color: $green;

	&:before {
		content: "";
		display: block;
		width: .33em;
		height: .33em;
		border: 1px solid $white;
		border-width: 1px 1px 0 0;
		transform: translateY(33%) rotate(-45deg);
		transition: transform .8s;
	}

	&:hover:before {
		transform: translateY(0) rotate(-45deg);
		transition-duration: .3s;
	}
}
