/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    mixins for media queries
    Authors     mac signer
    Datum       11.05.18

*****************************************************************************/

@mixin media-ie() {
	@media screen\0 {
		@content;
	}

	@media screen\9 {
		@content;
	}
}

@mixin media-max($max-width) {
	@media screen and ( max-width: $max-width ) {
		@content;
	}
}

@mixin media-max-content-width() {
	@include media-max( $breakpoint-max-content-width ) {
		@content;
	}
}

@mixin media-desktop-small() {
	@include media-max( $breakpoint-desktop-small ) {
		@content;
	}
}

@mixin media-desktop-small() {
	@include media-max( $breakpoint-desktop-small ) {
		@content;
	}
}

@mixin media-tablet() {
	@include media-max( $breakpoint-tablet ) {
		@content;
	}
}

@mixin media-mobile() {
	@include media-max( $breakpoint-mobile ) {
		@content;
	}
}
