/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    Contains classes/helpers, mostly used by the dev-team
    Authors
    Datum

*****************************************************************************/

.object-fit-fallback{
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	height:100%;

	img{
		opacity: 0;
	}
}

/*
Helper

- `.clearfix`
- `.invisible`
- `.overflow-hidden`
- `.text-align-left`
- `.text-align-center`
- `.text-align-right`

Styleguide 100.20
*/

.clearfix {
	@include clearfix;
}

.invisible {
	position: absolute !important;
	left: -9999em !important;
	display: none !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.text-align-left {
	text-align: left !important;
}

.text-align-center {
	text-align: center !important;
}

.text-align-right {
	text-align: right !important;
}


/*
.remove-margin-*

- `.remove-margin-top`
- `.remove-margin-top-desktop-small`
- `.remove-margin-top-tablet`
- `.remove-margin-top-mobile`

---

- `.remove-margin-right`
- `.remove-margin-right-desktop-small`
- `.remove-margin-right-tablet`
- `.remove-margin-right-mobile`

---

- `.remove-margin-bottom`
- `.remove-margin-bottom-desktop-small`
- `.remove-margin-bottom-tablet`
- `.remove-margin-bottom-mobile`

---

- `.remove-margin-left`
- `.remove-margin-left-desktop-small`
- `.remove-margin-left-tablet`
- `.remove-margin-left-mobile`

Styleguide 100.21
*/

.remove-margin-top {
	@include media-helper() {
		margin-top: 0 !important;
	}
}

.remove-margin-right {
	@include media-helper() {
		margin-right: 0 !important;
	}
}

.remove-margin-bottom {
	@include media-helper() {
		margin-bottom: 0 !important;
	}
}

.remove-margin-left {
	@include media-helper() {
		margin-left: 0 !important;
	}
}

.remove-padding-top {
	@include media-helper() {
		padding-top: 0 !important;
	}
}

.remove-padding-right {
	@include media-helper() {
		padding-right: 0 !important;
	}
}

.remove-padding-bottom {
	@include media-helper() {
		padding-bottom: 0 !important;
	}
}

.remove-padding-left {
	@include media-helper() {
		padding-left: 0 !important;
	}
}


/*
.hide-on-*

- `.hide-on-desktop-small`
- `.hide-on-tablet`
- `.hide-on-mobile`

Styleguide 100.22
*/

.hide-on-desktop-small {
	@include media-desktop-small() {
		display: none !important;
	}
}

.hide-on-tablet {
	@include media-tablet() {
		display: none !important;
	}
}

.hide-on-mobile {
	@include media-mobile() {
		display: none !important;
	}
}


/*
.show-on-*

- `.show-on-desktop-small`
- `.show-on-desktop-small--flex`
- `.show-on-desktop-small--inline`
- `.show-on-desktop-small--inline-block`

---

- `.show-on-tablet`
- `.show-on-tablet--flex`
- `.show-on-tablet--inline`
- `.show-on-tablet--inline-block`

---

- `.show-on-mobile`
- `.show-on-mobile--flex`
- `.show-on-mobile--inline`
- `.show-on-mobile--inline-block`

Styleguide 100.23
*/

.show-on-desktop-small {
	display: none !important;

	@include media-desktop-small() {
		display: block !important;

		&--flex {
			display: flex !important;
		}

		&--inline {
			display: inline !important;
		}

		&--inline-block {
			display: inline-block !important;
		}
	}
}

.show-on-tablet {
	display: none !important;

	@include media-tablet() {
		display: block !important;

		&--flex {
			display: flex !important;
		}

		&--inline {
			display: inline !important;
		}

		&--inline-block {
			display: inline-block !important;
		}
	}
}

.show-on-mobile {
	display: none !important;

	@include media-mobile() {
		display: block !important;

		&--flex {
			display: flex !important;
		}

		&--inline {
			display: inline !important;
		}

		&--inline-block {
			display: inline-block !important;
		}
	}
}
