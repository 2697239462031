@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?jl5jzu') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?jl5jzu') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?jl5jzu##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.rh-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rh-icon-home {
  &:before {
    content: $rh-icon-home; 
  }
}
.rh-icon-home2 {
  &:before {
    content: $rh-icon-home2; 
  }
}
.rh-icon-home3 {
  &:before {
    content: $rh-icon-home3; 
  }
}
.rh-icon-office {
  &:before {
    content: $rh-icon-office; 
  }
}
.rh-icon-newspaper {
  &:before {
    content: $rh-icon-newspaper; 
  }
}
.rh-icon-pencil {
  &:before {
    content: $rh-icon-pencil; 
  }
}
.rh-icon-pencil2 {
  &:before {
    content: $rh-icon-pencil2; 
  }
}
.rh-icon-quill {
  &:before {
    content: $rh-icon-quill; 
  }
}
.rh-icon-pen {
  &:before {
    content: $rh-icon-pen; 
  }
}
.rh-icon-blog {
  &:before {
    content: $rh-icon-blog; 
  }
}
.rh-icon-eyedropper {
  &:before {
    content: $rh-icon-eyedropper; 
  }
}
.rh-icon-droplet {
  &:before {
    content: $rh-icon-droplet; 
  }
}
.rh-icon-paint-format {
  &:before {
    content: $rh-icon-paint-format; 
  }
}
.rh-icon-image {
  &:before {
    content: $rh-icon-image; 
  }
}
.rh-icon-images {
  &:before {
    content: $rh-icon-images; 
  }
}
.rh-icon-camera {
  &:before {
    content: $rh-icon-camera; 
  }
}
.rh-icon-headphones {
  &:before {
    content: $rh-icon-headphones; 
  }
}
.rh-icon-music {
  &:before {
    content: $rh-icon-music; 
  }
}
.rh-icon-play {
  &:before {
    content: $rh-icon-play; 
  }
}
.rh-icon-film {
  &:before {
    content: $rh-icon-film; 
  }
}
.rh-icon-video-camera {
  &:before {
    content: $rh-icon-video-camera; 
  }
}
.rh-icon-dice {
  &:before {
    content: $rh-icon-dice; 
  }
}
.rh-icon-pacman {
  &:before {
    content: $rh-icon-pacman; 
  }
}
.rh-icon-spades {
  &:before {
    content: $rh-icon-spades; 
  }
}
.rh-icon-clubs {
  &:before {
    content: $rh-icon-clubs; 
  }
}
.rh-icon-diamonds {
  &:before {
    content: $rh-icon-diamonds; 
  }
}
.rh-icon-bullhorn {
  &:before {
    content: $rh-icon-bullhorn; 
  }
}
.rh-icon-connection {
  &:before {
    content: $rh-icon-connection; 
  }
}
.rh-icon-podcast {
  &:before {
    content: $rh-icon-podcast; 
  }
}
.rh-icon-feed {
  &:before {
    content: $rh-icon-feed; 
  }
}
.rh-icon-mic {
  &:before {
    content: $rh-icon-mic; 
  }
}
.rh-icon-book {
  &:before {
    content: $rh-icon-book; 
  }
}
.rh-icon-books {
  &:before {
    content: $rh-icon-books; 
  }
}
.rh-icon-library {
  &:before {
    content: $rh-icon-library; 
  }
}
.rh-icon-file-text {
  &:before {
    content: $rh-icon-file-text; 
  }
}
.rh-icon-profile {
  &:before {
    content: $rh-icon-profile; 
  }
}
.rh-icon-file-empty {
  &:before {
    content: $rh-icon-file-empty; 
  }
}
.rh-icon-files-empty {
  &:before {
    content: $rh-icon-files-empty; 
  }
}
.rh-icon-file-text2 {
  &:before {
    content: $rh-icon-file-text2; 
  }
}
.rh-icon-file-picture {
  &:before {
    content: $rh-icon-file-picture; 
  }
}
.rh-icon-file-music {
  &:before {
    content: $rh-icon-file-music; 
  }
}
.rh-icon-file-play {
  &:before {
    content: $rh-icon-file-play; 
  }
}
.rh-icon-file-video {
  &:before {
    content: $rh-icon-file-video; 
  }
}
.rh-icon-file-zip {
  &:before {
    content: $rh-icon-file-zip; 
  }
}
.rh-icon-copy {
  &:before {
    content: $rh-icon-copy; 
  }
}
.rh-icon-paste {
  &:before {
    content: $rh-icon-paste; 
  }
}
.rh-icon-stack {
  &:before {
    content: $rh-icon-stack; 
  }
}
.rh-icon-folder {
  &:before {
    content: $rh-icon-folder; 
  }
}
.rh-icon-folder-open {
  &:before {
    content: $rh-icon-folder-open; 
  }
}
.rh-icon-folder-plus {
  &:before {
    content: $rh-icon-folder-plus; 
  }
}
.rh-icon-folder-minus {
  &:before {
    content: $rh-icon-folder-minus; 
  }
}
.rh-icon-folder-download {
  &:before {
    content: $rh-icon-folder-download; 
  }
}
.rh-icon-folder-upload {
  &:before {
    content: $rh-icon-folder-upload; 
  }
}
.rh-icon-price-tag {
  &:before {
    content: $rh-icon-price-tag; 
  }
}
.rh-icon-price-tags {
  &:before {
    content: $rh-icon-price-tags; 
  }
}
.rh-icon-barcode {
  &:before {
    content: $rh-icon-barcode; 
  }
}
.rh-icon-qrcode {
  &:before {
    content: $rh-icon-qrcode; 
  }
}
.rh-icon-ticket {
  &:before {
    content: $rh-icon-ticket; 
  }
}
.rh-icon-cart {
  &:before {
    content: $rh-icon-cart; 
  }
}
.rh-icon-coin-dollar {
  &:before {
    content: $rh-icon-coin-dollar; 
  }
}
.rh-icon-coin-euro {
  &:before {
    content: $rh-icon-coin-euro; 
  }
}
.rh-icon-coin-pound {
  &:before {
    content: $rh-icon-coin-pound; 
  }
}
.rh-icon-coin-yen {
  &:before {
    content: $rh-icon-coin-yen; 
  }
}
.rh-icon-credit-card {
  &:before {
    content: $rh-icon-credit-card; 
  }
}
.rh-icon-calculator {
  &:before {
    content: $rh-icon-calculator; 
  }
}
.rh-icon-lifebuoy {
  &:before {
    content: $rh-icon-lifebuoy; 
  }
}
.rh-icon-phone {
  &:before {
    content: $rh-icon-phone; 
  }
}
.rh-icon-phone-hang-up {
  &:before {
    content: $rh-icon-phone-hang-up; 
  }
}
.rh-icon-address-book {
  &:before {
    content: $rh-icon-address-book; 
  }
}
.rh-icon-envelop {
  &:before {
    content: $rh-icon-envelop; 
  }
}
.rh-icon-pushpin {
  &:before {
    content: $rh-icon-pushpin; 
  }
}
.rh-icon-location {
  &:before {
    content: $rh-icon-location; 
  }
}
.rh-icon-location2 {
  &:before {
    content: $rh-icon-location2; 
  }
}
.rh-icon-compass {
  &:before {
    content: $rh-icon-compass; 
  }
}
.rh-icon-compass2 {
  &:before {
    content: $rh-icon-compass2; 
  }
}
.rh-icon-map {
  &:before {
    content: $rh-icon-map; 
  }
}
.rh-icon-map2 {
  &:before {
    content: $rh-icon-map2; 
  }
}
.rh-icon-history {
  &:before {
    content: $rh-icon-history; 
  }
}
.rh-icon-clock {
  &:before {
    content: $rh-icon-clock; 
  }
}
.rh-icon-clock2 {
  &:before {
    content: $rh-icon-clock2; 
  }
}
.rh-icon-alarm {
  &:before {
    content: $rh-icon-alarm; 
  }
}
.rh-icon-bell {
  &:before {
    content: $rh-icon-bell; 
  }
}
.rh-icon-stopwatch {
  &:before {
    content: $rh-icon-stopwatch; 
  }
}
.rh-icon-calendar {
  &:before {
    content: $rh-icon-calendar; 
  }
}
.rh-icon-printer {
  &:before {
    content: $rh-icon-printer; 
  }
}
.rh-icon-keyboard {
  &:before {
    content: $rh-icon-keyboard; 
  }
}
.rh-icon-display {
  &:before {
    content: $rh-icon-display; 
  }
}
.rh-icon-laptop {
  &:before {
    content: $rh-icon-laptop; 
  }
}
.rh-icon-mobile {
  &:before {
    content: $rh-icon-mobile; 
  }
}
.rh-icon-mobile2 {
  &:before {
    content: $rh-icon-mobile2; 
  }
}
.rh-icon-tablet {
  &:before {
    content: $rh-icon-tablet; 
  }
}
.rh-icon-tv {
  &:before {
    content: $rh-icon-tv; 
  }
}
.rh-icon-drawer {
  &:before {
    content: $rh-icon-drawer; 
  }
}
.rh-icon-drawer2 {
  &:before {
    content: $rh-icon-drawer2; 
  }
}
.rh-icon-box-add {
  &:before {
    content: $rh-icon-box-add; 
  }
}
.rh-icon-box-remove {
  &:before {
    content: $rh-icon-box-remove; 
  }
}
.rh-icon-download {
  &:before {
    content: $rh-icon-download; 
  }
}
.rh-icon-upload {
  &:before {
    content: $rh-icon-upload; 
  }
}
.rh-icon-floppy-disk {
  &:before {
    content: $rh-icon-floppy-disk; 
  }
}
.rh-icon-drive {
  &:before {
    content: $rh-icon-drive; 
  }
}
.rh-icon-database {
  &:before {
    content: $rh-icon-database; 
  }
}
.rh-icon-undo {
  &:before {
    content: $rh-icon-undo; 
  }
}
.rh-icon-redo {
  &:before {
    content: $rh-icon-redo; 
  }
}
.rh-icon-undo2 {
  &:before {
    content: $rh-icon-undo2; 
  }
}
.rh-icon-redo2 {
  &:before {
    content: $rh-icon-redo2; 
  }
}
.rh-icon-forward {
  &:before {
    content: $rh-icon-forward; 
  }
}
.rh-icon-reply {
  &:before {
    content: $rh-icon-reply; 
  }
}
.rh-icon-bubble {
  &:before {
    content: $rh-icon-bubble; 
  }
}
.rh-icon-bubbles {
  &:before {
    content: $rh-icon-bubbles; 
  }
}
.rh-icon-bubbles2 {
  &:before {
    content: $rh-icon-bubbles2; 
  }
}
.rh-icon-bubble2 {
  &:before {
    content: $rh-icon-bubble2; 
  }
}
.rh-icon-bubbles3 {
  &:before {
    content: $rh-icon-bubbles3; 
  }
}
.rh-icon-bubbles4 {
  &:before {
    content: $rh-icon-bubbles4; 
  }
}
.rh-icon-user {
  &:before {
    content: $rh-icon-user; 
  }
}
.rh-icon-users {
  &:before {
    content: $rh-icon-users; 
  }
}
.rh-icon-user-plus {
  &:before {
    content: $rh-icon-user-plus; 
  }
}
.rh-icon-user-minus {
  &:before {
    content: $rh-icon-user-minus; 
  }
}
.rh-icon-user-check {
  &:before {
    content: $rh-icon-user-check; 
  }
}
.rh-icon-user-tie {
  &:before {
    content: $rh-icon-user-tie; 
  }
}
.rh-icon-quotes-left {
  &:before {
    content: $rh-icon-quotes-left; 
  }
}
.rh-icon-quotes-right {
  &:before {
    content: $rh-icon-quotes-right; 
  }
}
.rh-icon-hour-glass {
  &:before {
    content: $rh-icon-hour-glass; 
  }
}
.rh-icon-spinner {
  &:before {
    content: $rh-icon-spinner; 
  }
}
.rh-icon-spinner2 {
  &:before {
    content: $rh-icon-spinner2; 
  }
}
.rh-icon-spinner3 {
  &:before {
    content: $rh-icon-spinner3; 
  }
}
.rh-icon-spinner4 {
  &:before {
    content: $rh-icon-spinner4; 
  }
}
.rh-icon-spinner5 {
  &:before {
    content: $rh-icon-spinner5; 
  }
}
.rh-icon-spinner6 {
  &:before {
    content: $rh-icon-spinner6; 
  }
}
.rh-icon-spinner7 {
  &:before {
    content: $rh-icon-spinner7; 
  }
}
.rh-icon-spinner8 {
  &:before {
    content: $rh-icon-spinner8; 
  }
}
.rh-icon-spinner9 {
  &:before {
    content: $rh-icon-spinner9; 
  }
}
.rh-icon-spinner10 {
  &:before {
    content: $rh-icon-spinner10; 
  }
}
.rh-icon-spinner11 {
  &:before {
    content: $rh-icon-spinner11; 
  }
}
.rh-icon-binoculars {
  &:before {
    content: $rh-icon-binoculars; 
  }
}
.rh-icon-search {
  &:before {
    content: $rh-icon-search; 
  }
}
.rh-icon-zoom-in {
  &:before {
    content: $rh-icon-zoom-in; 
  }
}
.rh-icon-zoom-out {
  &:before {
    content: $rh-icon-zoom-out; 
  }
}
.rh-icon-enlarge {
  &:before {
    content: $rh-icon-enlarge; 
  }
}
.rh-icon-shrink {
  &:before {
    content: $rh-icon-shrink; 
  }
}
.rh-icon-enlarge2 {
  &:before {
    content: $rh-icon-enlarge2; 
  }
}
.rh-icon-shrink2 {
  &:before {
    content: $rh-icon-shrink2; 
  }
}
.rh-icon-key {
  &:before {
    content: $rh-icon-key; 
  }
}
.rh-icon-key2 {
  &:before {
    content: $rh-icon-key2; 
  }
}
.rh-icon-lock {
  &:before {
    content: $rh-icon-lock; 
  }
}
.rh-icon-unlocked {
  &:before {
    content: $rh-icon-unlocked; 
  }
}
.rh-icon-wrench {
  &:before {
    content: $rh-icon-wrench; 
  }
}
.rh-icon-equalizer {
  &:before {
    content: $rh-icon-equalizer; 
  }
}
.rh-icon-equalizer2 {
  &:before {
    content: $rh-icon-equalizer2; 
  }
}
.rh-icon-cog {
  &:before {
    content: $rh-icon-cog; 
  }
}
.rh-icon-cogs {
  &:before {
    content: $rh-icon-cogs; 
  }
}
.rh-icon-hammer {
  &:before {
    content: $rh-icon-hammer; 
  }
}
.rh-icon-magic-wand {
  &:before {
    content: $rh-icon-magic-wand; 
  }
}
.rh-icon-aid-kit {
  &:before {
    content: $rh-icon-aid-kit; 
  }
}
.rh-icon-bug {
  &:before {
    content: $rh-icon-bug; 
  }
}
.rh-icon-pie-chart {
  &:before {
    content: $rh-icon-pie-chart; 
  }
}
.rh-icon-stats-dots {
  &:before {
    content: $rh-icon-stats-dots; 
  }
}
.rh-icon-stats-bars {
  &:before {
    content: $rh-icon-stats-bars; 
  }
}
.rh-icon-stats-bars2 {
  &:before {
    content: $rh-icon-stats-bars2; 
  }
}
.rh-icon-trophy {
  &:before {
    content: $rh-icon-trophy; 
  }
}
.rh-icon-gift {
  &:before {
    content: $rh-icon-gift; 
  }
}
.rh-icon-glass {
  &:before {
    content: $rh-icon-glass; 
  }
}
.rh-icon-glass2 {
  &:before {
    content: $rh-icon-glass2; 
  }
}
.rh-icon-mug {
  &:before {
    content: $rh-icon-mug; 
  }
}
.rh-icon-spoon-knife {
  &:before {
    content: $rh-icon-spoon-knife; 
  }
}
.rh-icon-leaf {
  &:before {
    content: $rh-icon-leaf; 
  }
}
.rh-icon-rocket {
  &:before {
    content: $rh-icon-rocket; 
  }
}
.rh-icon-meter {
  &:before {
    content: $rh-icon-meter; 
  }
}
.rh-icon-meter2 {
  &:before {
    content: $rh-icon-meter2; 
  }
}
.rh-icon-hammer2 {
  &:before {
    content: $rh-icon-hammer2; 
  }
}
.rh-icon-fire {
  &:before {
    content: $rh-icon-fire; 
  }
}
.rh-icon-lab {
  &:before {
    content: $rh-icon-lab; 
  }
}
.rh-icon-magnet {
  &:before {
    content: $rh-icon-magnet; 
  }
}
.rh-icon-bin {
  &:before {
    content: $rh-icon-bin; 
  }
}
.rh-icon-bin2 {
  &:before {
    content: $rh-icon-bin2; 
  }
}
.rh-icon-briefcase {
  &:before {
    content: $rh-icon-briefcase; 
  }
}
.rh-icon-airplane {
  &:before {
    content: $rh-icon-airplane; 
  }
}
.rh-icon-truck {
  &:before {
    content: $rh-icon-truck; 
  }
}
.rh-icon-accessibility {
  &:before {
    content: $rh-icon-accessibility; 
  }
}
.rh-icon-target {
  &:before {
    content: $rh-icon-target; 
  }
}
.rh-icon-shield {
  &:before {
    content: $rh-icon-shield; 
  }
}
.rh-icon-power {
  &:before {
    content: $rh-icon-power; 
  }
}
.rh-icon-switch {
  &:before {
    content: $rh-icon-switch; 
  }
}
.rh-icon-power-cord {
  &:before {
    content: $rh-icon-power-cord; 
  }
}
.rh-icon-clipboard {
  &:before {
    content: $rh-icon-clipboard; 
  }
}
.rh-icon-list-numbered {
  &:before {
    content: $rh-icon-list-numbered; 
  }
}
.rh-icon-list {
  &:before {
    content: $rh-icon-list; 
  }
}
.rh-icon-list2 {
  &:before {
    content: $rh-icon-list2; 
  }
}
.rh-icon-tree {
  &:before {
    content: $rh-icon-tree; 
  }
}
.rh-icon-menu {
  &:before {
    content: $rh-icon-menu; 
  }
}
.rh-icon-menu2 {
  &:before {
    content: $rh-icon-menu2; 
  }
}
.rh-icon-menu3 {
  &:before {
    content: $rh-icon-menu3; 
  }
}
.rh-icon-menu4 {
  &:before {
    content: $rh-icon-menu4; 
  }
}
.rh-icon-cloud {
  &:before {
    content: $rh-icon-cloud; 
  }
}
.rh-icon-cloud-download {
  &:before {
    content: $rh-icon-cloud-download; 
  }
}
.rh-icon-cloud-upload {
  &:before {
    content: $rh-icon-cloud-upload; 
  }
}
.rh-icon-cloud-check {
  &:before {
    content: $rh-icon-cloud-check; 
  }
}
.rh-icon-download2 {
  &:before {
    content: $rh-icon-download2; 
  }
}
.rh-icon-upload2 {
  &:before {
    content: $rh-icon-upload2; 
  }
}
.rh-icon-download3 {
  &:before {
    content: $rh-icon-download3; 
  }
}
.rh-icon-upload3 {
  &:before {
    content: $rh-icon-upload3; 
  }
}
.rh-icon-sphere {
  &:before {
    content: $rh-icon-sphere; 
  }
}
.rh-icon-earth {
  &:before {
    content: $rh-icon-earth; 
  }
}
.rh-icon-link {
  &:before {
    content: $rh-icon-link; 
  }
}
.rh-icon-flag {
  &:before {
    content: $rh-icon-flag; 
  }
}
.rh-icon-attachment {
  &:before {
    content: $rh-icon-attachment; 
  }
}
.rh-icon-eye {
  &:before {
    content: $rh-icon-eye; 
  }
}
.rh-icon-eye-plus {
  &:before {
    content: $rh-icon-eye-plus; 
  }
}
.rh-icon-eye-minus {
  &:before {
    content: $rh-icon-eye-minus; 
  }
}
.rh-icon-eye-blocked {
  &:before {
    content: $rh-icon-eye-blocked; 
  }
}
.rh-icon-bookmark {
  &:before {
    content: $rh-icon-bookmark; 
  }
}
.rh-icon-bookmarks {
  &:before {
    content: $rh-icon-bookmarks; 
  }
}
.rh-icon-sun {
  &:before {
    content: $rh-icon-sun; 
  }
}
.rh-icon-contrast {
  &:before {
    content: $rh-icon-contrast; 
  }
}
.rh-icon-brightness-contrast {
  &:before {
    content: $rh-icon-brightness-contrast; 
  }
}
.rh-icon-star-empty {
  &:before {
    content: $rh-icon-star-empty; 
  }
}
.rh-icon-star-half {
  &:before {
    content: $rh-icon-star-half; 
  }
}
.rh-icon-star-full {
  &:before {
    content: $rh-icon-star-full; 
  }
}
.rh-icon-heart {
  &:before {
    content: $rh-icon-heart; 
  }
}
.rh-icon-heart-broken {
  &:before {
    content: $rh-icon-heart-broken; 
  }
}
.rh-icon-man {
  &:before {
    content: $rh-icon-man; 
  }
}
.rh-icon-woman {
  &:before {
    content: $rh-icon-woman; 
  }
}
.rh-icon-man-woman {
  &:before {
    content: $rh-icon-man-woman; 
  }
}
.rh-icon-happy {
  &:before {
    content: $rh-icon-happy; 
  }
}
.rh-icon-happy2 {
  &:before {
    content: $rh-icon-happy2; 
  }
}
.rh-icon-smile {
  &:before {
    content: $rh-icon-smile; 
  }
}
.rh-icon-smile2 {
  &:before {
    content: $rh-icon-smile2; 
  }
}
.rh-icon-tongue {
  &:before {
    content: $rh-icon-tongue; 
  }
}
.rh-icon-tongue2 {
  &:before {
    content: $rh-icon-tongue2; 
  }
}
.rh-icon-sad {
  &:before {
    content: $rh-icon-sad; 
  }
}
.rh-icon-sad2 {
  &:before {
    content: $rh-icon-sad2; 
  }
}
.rh-icon-wink {
  &:before {
    content: $rh-icon-wink; 
  }
}
.rh-icon-wink2 {
  &:before {
    content: $rh-icon-wink2; 
  }
}
.rh-icon-grin {
  &:before {
    content: $rh-icon-grin; 
  }
}
.rh-icon-grin2 {
  &:before {
    content: $rh-icon-grin2; 
  }
}
.rh-icon-cool {
  &:before {
    content: $rh-icon-cool; 
  }
}
.rh-icon-cool2 {
  &:before {
    content: $rh-icon-cool2; 
  }
}
.rh-icon-angry {
  &:before {
    content: $rh-icon-angry; 
  }
}
.rh-icon-angry2 {
  &:before {
    content: $rh-icon-angry2; 
  }
}
.rh-icon-evil {
  &:before {
    content: $rh-icon-evil; 
  }
}
.rh-icon-evil2 {
  &:before {
    content: $rh-icon-evil2; 
  }
}
.rh-icon-shocked {
  &:before {
    content: $rh-icon-shocked; 
  }
}
.rh-icon-shocked2 {
  &:before {
    content: $rh-icon-shocked2; 
  }
}
.rh-icon-baffled {
  &:before {
    content: $rh-icon-baffled; 
  }
}
.rh-icon-baffled2 {
  &:before {
    content: $rh-icon-baffled2; 
  }
}
.rh-icon-confused {
  &:before {
    content: $rh-icon-confused; 
  }
}
.rh-icon-confused2 {
  &:before {
    content: $rh-icon-confused2; 
  }
}
.rh-icon-neutral {
  &:before {
    content: $rh-icon-neutral; 
  }
}
.rh-icon-neutral2 {
  &:before {
    content: $rh-icon-neutral2; 
  }
}
.rh-icon-hipster {
  &:before {
    content: $rh-icon-hipster; 
  }
}
.rh-icon-hipster2 {
  &:before {
    content: $rh-icon-hipster2; 
  }
}
.rh-icon-wondering {
  &:before {
    content: $rh-icon-wondering; 
  }
}
.rh-icon-wondering2 {
  &:before {
    content: $rh-icon-wondering2; 
  }
}
.rh-icon-sleepy {
  &:before {
    content: $rh-icon-sleepy; 
  }
}
.rh-icon-sleepy2 {
  &:before {
    content: $rh-icon-sleepy2; 
  }
}
.rh-icon-frustrated {
  &:before {
    content: $rh-icon-frustrated; 
  }
}
.rh-icon-frustrated2 {
  &:before {
    content: $rh-icon-frustrated2; 
  }
}
.rh-icon-crying {
  &:before {
    content: $rh-icon-crying; 
  }
}
.rh-icon-crying2 {
  &:before {
    content: $rh-icon-crying2; 
  }
}
.rh-icon-point-up {
  &:before {
    content: $rh-icon-point-up; 
  }
}
.rh-icon-point-right {
  &:before {
    content: $rh-icon-point-right; 
  }
}
.rh-icon-point-down {
  &:before {
    content: $rh-icon-point-down; 
  }
}
.rh-icon-point-left {
  &:before {
    content: $rh-icon-point-left; 
  }
}
.rh-icon-warning {
  &:before {
    content: $rh-icon-warning; 
  }
}
.rh-icon-notification {
  &:before {
    content: $rh-icon-notification; 
  }
}
.rh-icon-question {
  &:before {
    content: $rh-icon-question; 
  }
}
.rh-icon-plus {
  &:before {
    content: $rh-icon-plus; 
  }
}
.rh-icon-minus {
  &:before {
    content: $rh-icon-minus; 
  }
}
.rh-icon-info {
  &:before {
    content: $rh-icon-info; 
  }
}
.rh-icon-cancel-circle {
  &:before {
    content: $rh-icon-cancel-circle; 
  }
}
.rh-icon-blocked {
  &:before {
    content: $rh-icon-blocked; 
  }
}
.rh-icon-cross {
  &:before {
    content: $rh-icon-cross; 
  }
}
.rh-icon-checkmark {
  &:before {
    content: $rh-icon-checkmark; 
  }
}
.rh-icon-checkmark2 {
  &:before {
    content: $rh-icon-checkmark2; 
  }
}
.rh-icon-spell-check {
  &:before {
    content: $rh-icon-spell-check; 
  }
}
.rh-icon-enter {
  &:before {
    content: $rh-icon-enter; 
  }
}
.rh-icon-exit {
  &:before {
    content: $rh-icon-exit; 
  }
}
.rh-icon-play2 {
  &:before {
    content: $rh-icon-play2; 
  }
}
.rh-icon-pause {
  &:before {
    content: $rh-icon-pause; 
  }
}
.rh-icon-stop {
  &:before {
    content: $rh-icon-stop; 
  }
}
.rh-icon-previous {
  &:before {
    content: $rh-icon-previous; 
  }
}
.rh-icon-next {
  &:before {
    content: $rh-icon-next; 
  }
}
.rh-icon-backward {
  &:before {
    content: $rh-icon-backward; 
  }
}
.rh-icon-forward2 {
  &:before {
    content: $rh-icon-forward2; 
  }
}
.rh-icon-play3 {
  &:before {
    content: $rh-icon-play3; 
  }
}
.rh-icon-pause2 {
  &:before {
    content: $rh-icon-pause2; 
  }
}
.rh-icon-stop2 {
  &:before {
    content: $rh-icon-stop2; 
  }
}
.rh-icon-backward2 {
  &:before {
    content: $rh-icon-backward2; 
  }
}
.rh-icon-forward3 {
  &:before {
    content: $rh-icon-forward3; 
  }
}
.rh-icon-first {
  &:before {
    content: $rh-icon-first; 
  }
}
.rh-icon-last {
  &:before {
    content: $rh-icon-last; 
  }
}
.rh-icon-previous2 {
  &:before {
    content: $rh-icon-previous2; 
  }
}
.rh-icon-next2 {
  &:before {
    content: $rh-icon-next2; 
  }
}
.rh-icon-eject {
  &:before {
    content: $rh-icon-eject; 
  }
}
.rh-icon-volume-high {
  &:before {
    content: $rh-icon-volume-high; 
  }
}
.rh-icon-volume-medium {
  &:before {
    content: $rh-icon-volume-medium; 
  }
}
.rh-icon-volume-low {
  &:before {
    content: $rh-icon-volume-low; 
  }
}
.rh-icon-volume-mute {
  &:before {
    content: $rh-icon-volume-mute; 
  }
}
.rh-icon-volume-mute2 {
  &:before {
    content: $rh-icon-volume-mute2; 
  }
}
.rh-icon-volume-increase {
  &:before {
    content: $rh-icon-volume-increase; 
  }
}
.rh-icon-volume-decrease {
  &:before {
    content: $rh-icon-volume-decrease; 
  }
}
.rh-icon-loop {
  &:before {
    content: $rh-icon-loop; 
  }
}
.rh-icon-loop2 {
  &:before {
    content: $rh-icon-loop2; 
  }
}
.rh-icon-infinite {
  &:before {
    content: $rh-icon-infinite; 
  }
}
.rh-icon-shuffle {
  &:before {
    content: $rh-icon-shuffle; 
  }
}
.rh-icon-arrow-up-left {
  &:before {
    content: $rh-icon-arrow-up-left; 
  }
}
.rh-icon-arrow-up {
  &:before {
    content: $rh-icon-arrow-up; 
  }
}
.rh-icon-arrow-up-right {
  &:before {
    content: $rh-icon-arrow-up-right; 
  }
}
.rh-icon-arrow-right {
  &:before {
    content: $rh-icon-arrow-right; 
  }
}
.rh-icon-arrow-down-right {
  &:before {
    content: $rh-icon-arrow-down-right; 
  }
}
.rh-icon-arrow-down {
  &:before {
    content: $rh-icon-arrow-down; 
  }
}
.rh-icon-arrow-down-left {
  &:before {
    content: $rh-icon-arrow-down-left; 
  }
}
.rh-icon-arrow-left {
  &:before {
    content: $rh-icon-arrow-left; 
  }
}
.rh-icon-arrow-up-left2 {
  &:before {
    content: $rh-icon-arrow-up-left2; 
  }
}
.rh-icon-arrow-up2 {
  &:before {
    content: $rh-icon-arrow-up2; 
  }
}
.rh-icon-arrow-up-right2 {
  &:before {
    content: $rh-icon-arrow-up-right2; 
  }
}
.rh-icon-arrow-right2 {
  &:before {
    content: $rh-icon-arrow-right2; 
  }
}
.rh-icon-arrow-down-right2 {
  &:before {
    content: $rh-icon-arrow-down-right2; 
  }
}
.rh-icon-arrow-down2 {
  &:before {
    content: $rh-icon-arrow-down2; 
  }
}
.rh-icon-arrow-down-left2 {
  &:before {
    content: $rh-icon-arrow-down-left2; 
  }
}
.rh-icon-arrow-left2 {
  &:before {
    content: $rh-icon-arrow-left2; 
  }
}
.rh-icon-circle-up {
  &:before {
    content: $rh-icon-circle-up; 
  }
}
.rh-icon-circle-right {
  &:before {
    content: $rh-icon-circle-right; 
  }
}
.rh-icon-circle-down {
  &:before {
    content: $rh-icon-circle-down; 
  }
}
.rh-icon-circle-left {
  &:before {
    content: $rh-icon-circle-left; 
  }
}
.rh-icon-tab {
  &:before {
    content: $rh-icon-tab; 
  }
}
.rh-icon-move-up {
  &:before {
    content: $rh-icon-move-up; 
  }
}
.rh-icon-move-down {
  &:before {
    content: $rh-icon-move-down; 
  }
}
.rh-icon-sort-alpha-asc {
  &:before {
    content: $rh-icon-sort-alpha-asc; 
  }
}
.rh-icon-sort-alpha-desc {
  &:before {
    content: $rh-icon-sort-alpha-desc; 
  }
}
.rh-icon-sort-numeric-asc {
  &:before {
    content: $rh-icon-sort-numeric-asc; 
  }
}
.rh-icon-sort-numberic-desc {
  &:before {
    content: $rh-icon-sort-numberic-desc; 
  }
}
.rh-icon-sort-amount-asc {
  &:before {
    content: $rh-icon-sort-amount-asc; 
  }
}
.rh-icon-sort-amount-desc {
  &:before {
    content: $rh-icon-sort-amount-desc; 
  }
}
.rh-icon-command {
  &:before {
    content: $rh-icon-command; 
  }
}
.rh-icon-shift {
  &:before {
    content: $rh-icon-shift; 
  }
}
.rh-icon-ctrl {
  &:before {
    content: $rh-icon-ctrl; 
  }
}
.rh-icon-opt {
  &:before {
    content: $rh-icon-opt; 
  }
}
.rh-icon-checkbox-checked {
  &:before {
    content: $rh-icon-checkbox-checked; 
  }
}
.rh-icon-checkbox-unchecked {
  &:before {
    content: $rh-icon-checkbox-unchecked; 
  }
}
.rh-icon-radio-checked {
  &:before {
    content: $rh-icon-radio-checked; 
  }
}
.rh-icon-radio-checked2 {
  &:before {
    content: $rh-icon-radio-checked2; 
  }
}
.rh-icon-radio-unchecked {
  &:before {
    content: $rh-icon-radio-unchecked; 
  }
}
.rh-icon-crop {
  &:before {
    content: $rh-icon-crop; 
  }
}
.rh-icon-make-group {
  &:before {
    content: $rh-icon-make-group; 
  }
}
.rh-icon-ungroup {
  &:before {
    content: $rh-icon-ungroup; 
  }
}
.rh-icon-scissors {
  &:before {
    content: $rh-icon-scissors; 
  }
}
.rh-icon-filter {
  &:before {
    content: $rh-icon-filter; 
  }
}
.rh-icon-font {
  &:before {
    content: $rh-icon-font; 
  }
}
.rh-icon-ligature {
  &:before {
    content: $rh-icon-ligature; 
  }
}
.rh-icon-ligature2 {
  &:before {
    content: $rh-icon-ligature2; 
  }
}
.rh-icon-text-height {
  &:before {
    content: $rh-icon-text-height; 
  }
}
.rh-icon-text-width {
  &:before {
    content: $rh-icon-text-width; 
  }
}
.rh-icon-font-size {
  &:before {
    content: $rh-icon-font-size; 
  }
}
.rh-icon-bold {
  &:before {
    content: $rh-icon-bold; 
  }
}
.rh-icon-underline {
  &:before {
    content: $rh-icon-underline; 
  }
}
.rh-icon-italic {
  &:before {
    content: $rh-icon-italic; 
  }
}
.rh-icon-strikethrough {
  &:before {
    content: $rh-icon-strikethrough; 
  }
}
.rh-icon-omega {
  &:before {
    content: $rh-icon-omega; 
  }
}
.rh-icon-sigma {
  &:before {
    content: $rh-icon-sigma; 
  }
}
.rh-icon-page-break {
  &:before {
    content: $rh-icon-page-break; 
  }
}
.rh-icon-superscript {
  &:before {
    content: $rh-icon-superscript; 
  }
}
.rh-icon-subscript {
  &:before {
    content: $rh-icon-subscript; 
  }
}
.rh-icon-superscript2 {
  &:before {
    content: $rh-icon-superscript2; 
  }
}
.rh-icon-subscript2 {
  &:before {
    content: $rh-icon-subscript2; 
  }
}
.rh-icon-text-color {
  &:before {
    content: $rh-icon-text-color; 
  }
}
.rh-icon-pagebreak {
  &:before {
    content: $rh-icon-pagebreak; 
  }
}
.rh-icon-clear-formatting {
  &:before {
    content: $rh-icon-clear-formatting; 
  }
}
.rh-icon-table {
  &:before {
    content: $rh-icon-table; 
  }
}
.rh-icon-table2 {
  &:before {
    content: $rh-icon-table2; 
  }
}
.rh-icon-insert-template {
  &:before {
    content: $rh-icon-insert-template; 
  }
}
.rh-icon-pilcrow {
  &:before {
    content: $rh-icon-pilcrow; 
  }
}
.rh-icon-ltr {
  &:before {
    content: $rh-icon-ltr; 
  }
}
.rh-icon-rtl {
  &:before {
    content: $rh-icon-rtl; 
  }
}
.rh-icon-section {
  &:before {
    content: $rh-icon-section; 
  }
}
.rh-icon-paragraph-left {
  &:before {
    content: $rh-icon-paragraph-left; 
  }
}
.rh-icon-paragraph-center {
  &:before {
    content: $rh-icon-paragraph-center; 
  }
}
.rh-icon-paragraph-right {
  &:before {
    content: $rh-icon-paragraph-right; 
  }
}
.rh-icon-paragraph-justify {
  &:before {
    content: $rh-icon-paragraph-justify; 
  }
}
.rh-icon-indent-increase {
  &:before {
    content: $rh-icon-indent-increase; 
  }
}
.rh-icon-indent-decrease {
  &:before {
    content: $rh-icon-indent-decrease; 
  }
}
.rh-icon-share {
  &:before {
    content: $rh-icon-share; 
  }
}
.rh-icon-new-tab {
  &:before {
    content: $rh-icon-new-tab; 
  }
}
.rh-icon-embed {
  &:before {
    content: $rh-icon-embed; 
  }
}
.rh-icon-embed2 {
  &:before {
    content: $rh-icon-embed2; 
  }
}
.rh-icon-terminal {
  &:before {
    content: $rh-icon-terminal; 
  }
}
.rh-icon-share2 {
  &:before {
    content: $rh-icon-share2; 
  }
}
.rh-icon-mail {
  &:before {
    content: $rh-icon-mail; 
  }
}
.rh-icon-mail2 {
  &:before {
    content: $rh-icon-mail2; 
  }
}
.rh-icon-mail3 {
  &:before {
    content: $rh-icon-mail3; 
  }
}
.rh-icon-mail4 {
  &:before {
    content: $rh-icon-mail4; 
  }
}
.rh-icon-amazon {
  &:before {
    content: $rh-icon-amazon; 
  }
}
.rh-icon-google {
  &:before {
    content: $rh-icon-google; 
  }
}
.rh-icon-google2 {
  &:before {
    content: $rh-icon-google2; 
  }
}
.rh-icon-google3 {
  &:before {
    content: $rh-icon-google3; 
  }
}
.rh-icon-google-plus {
  &:before {
    content: $rh-icon-google-plus; 
  }
}
.rh-icon-google-plus2 {
  &:before {
    content: $rh-icon-google-plus2; 
  }
}
.rh-icon-google-plus3 {
  &:before {
    content: $rh-icon-google-plus3; 
  }
}
.rh-icon-hangouts {
  &:before {
    content: $rh-icon-hangouts; 
  }
}
.rh-icon-google-drive {
  &:before {
    content: $rh-icon-google-drive; 
  }
}
.rh-icon-facebook {
  &:before {
    content: $rh-icon-facebook; 
  }
}
.rh-icon-facebook2 {
  &:before {
    content: $rh-icon-facebook2; 
  }
}
.rh-icon-instagram {
  &:before {
    content: $rh-icon-instagram; 
  }
}
.rh-icon-whatsapp {
  &:before {
    content: $rh-icon-whatsapp; 
  }
}
.rh-icon-spotify {
  &:before {
    content: $rh-icon-spotify; 
  }
}
.rh-icon-telegram {
  &:before {
    content: $rh-icon-telegram; 
  }
}
.rh-icon-twitter {
  &:before {
    content: $rh-icon-twitter; 
  }
}
.rh-icon-vine {
  &:before {
    content: $rh-icon-vine; 
  }
}
.rh-icon-vk {
  &:before {
    content: $rh-icon-vk; 
  }
}
.rh-icon-renren {
  &:before {
    content: $rh-icon-renren; 
  }
}
.rh-icon-sina-weibo {
  &:before {
    content: $rh-icon-sina-weibo; 
  }
}
.rh-icon-rss {
  &:before {
    content: $rh-icon-rss; 
  }
}
.rh-icon-rss2 {
  &:before {
    content: $rh-icon-rss2; 
  }
}
.rh-icon-youtube {
  &:before {
    content: $rh-icon-youtube; 
  }
}
.rh-icon-youtube2 {
  &:before {
    content: $rh-icon-youtube2; 
  }
}
.rh-icon-twitch {
  &:before {
    content: $rh-icon-twitch; 
  }
}
.rh-icon-vimeo {
  &:before {
    content: $rh-icon-vimeo; 
  }
}
.rh-icon-vimeo2 {
  &:before {
    content: $rh-icon-vimeo2; 
  }
}
.rh-icon-lanyrd {
  &:before {
    content: $rh-icon-lanyrd; 
  }
}
.rh-icon-flickr {
  &:before {
    content: $rh-icon-flickr; 
  }
}
.rh-icon-flickr2 {
  &:before {
    content: $rh-icon-flickr2; 
  }
}
.rh-icon-flickr3 {
  &:before {
    content: $rh-icon-flickr3; 
  }
}
.rh-icon-flickr4 {
  &:before {
    content: $rh-icon-flickr4; 
  }
}
.rh-icon-dribbble {
  &:before {
    content: $rh-icon-dribbble; 
  }
}
.rh-icon-behance {
  &:before {
    content: $rh-icon-behance; 
  }
}
.rh-icon-behance2 {
  &:before {
    content: $rh-icon-behance2; 
  }
}
.rh-icon-deviantart {
  &:before {
    content: $rh-icon-deviantart; 
  }
}
.rh-icon-500px {
  &:before {
    content: $rh-icon-500px; 
  }
}
.rh-icon-steam {
  &:before {
    content: $rh-icon-steam; 
  }
}
.rh-icon-steam2 {
  &:before {
    content: $rh-icon-steam2; 
  }
}
.rh-icon-dropbox {
  &:before {
    content: $rh-icon-dropbox; 
  }
}
.rh-icon-onedrive {
  &:before {
    content: $rh-icon-onedrive; 
  }
}
.rh-icon-github {
  &:before {
    content: $rh-icon-github; 
  }
}
.rh-icon-npm {
  &:before {
    content: $rh-icon-npm; 
  }
}
.rh-icon-basecamp {
  &:before {
    content: $rh-icon-basecamp; 
  }
}
.rh-icon-trello {
  &:before {
    content: $rh-icon-trello; 
  }
}
.rh-icon-wordpress {
  &:before {
    content: $rh-icon-wordpress; 
  }
}
.rh-icon-joomla {
  &:before {
    content: $rh-icon-joomla; 
  }
}
.rh-icon-ello {
  &:before {
    content: $rh-icon-ello; 
  }
}
.rh-icon-blogger {
  &:before {
    content: $rh-icon-blogger; 
  }
}
.rh-icon-blogger2 {
  &:before {
    content: $rh-icon-blogger2; 
  }
}
.rh-icon-tumblr {
  &:before {
    content: $rh-icon-tumblr; 
  }
}
.rh-icon-tumblr2 {
  &:before {
    content: $rh-icon-tumblr2; 
  }
}
.rh-icon-yahoo {
  &:before {
    content: $rh-icon-yahoo; 
  }
}
.rh-icon-yahoo2 {
  &:before {
    content: $rh-icon-yahoo2; 
  }
}
.rh-icon-tux {
  &:before {
    content: $rh-icon-tux; 
  }
}
.rh-icon-appleinc {
  &:before {
    content: $rh-icon-appleinc; 
  }
}
.rh-icon-finder {
  &:before {
    content: $rh-icon-finder; 
  }
}
.rh-icon-android {
  &:before {
    content: $rh-icon-android; 
  }
}
.rh-icon-windows {
  &:before {
    content: $rh-icon-windows; 
  }
}
.rh-icon-windows8 {
  &:before {
    content: $rh-icon-windows8; 
  }
}
.rh-icon-soundcloud {
  &:before {
    content: $rh-icon-soundcloud; 
  }
}
.rh-icon-soundcloud2 {
  &:before {
    content: $rh-icon-soundcloud2; 
  }
}
.rh-icon-skype {
  &:before {
    content: $rh-icon-skype; 
  }
}
.rh-icon-reddit {
  &:before {
    content: $rh-icon-reddit; 
  }
}
.rh-icon-hackernews {
  &:before {
    content: $rh-icon-hackernews; 
  }
}
.rh-icon-wikipedia {
  &:before {
    content: $rh-icon-wikipedia; 
  }
}
.rh-icon-linkedin {
  &:before {
    content: $rh-icon-linkedin; 
  }
}
.rh-icon-linkedin2 {
  &:before {
    content: $rh-icon-linkedin2; 
  }
}
.rh-icon-lastfm {
  &:before {
    content: $rh-icon-lastfm; 
  }
}
.rh-icon-lastfm2 {
  &:before {
    content: $rh-icon-lastfm2; 
  }
}
.rh-icon-delicious {
  &:before {
    content: $rh-icon-delicious; 
  }
}
.rh-icon-stumbleupon {
  &:before {
    content: $rh-icon-stumbleupon; 
  }
}
.rh-icon-stumbleupon2 {
  &:before {
    content: $rh-icon-stumbleupon2; 
  }
}
.rh-icon-stackoverflow {
  &:before {
    content: $rh-icon-stackoverflow; 
  }
}
.rh-icon-pinterest {
  &:before {
    content: $rh-icon-pinterest; 
  }
}
.rh-icon-pinterest2 {
  &:before {
    content: $rh-icon-pinterest2; 
  }
}
.rh-icon-xing {
  &:before {
    content: $rh-icon-xing; 
  }
}
.rh-icon-xing2 {
  &:before {
    content: $rh-icon-xing2; 
  }
}
.rh-icon-flattr {
  &:before {
    content: $rh-icon-flattr; 
  }
}
.rh-icon-foursquare {
  &:before {
    content: $rh-icon-foursquare; 
  }
}
.rh-icon-yelp {
  &:before {
    content: $rh-icon-yelp; 
  }
}
.rh-icon-paypal {
  &:before {
    content: $rh-icon-paypal; 
  }
}
.rh-icon-chrome {
  &:before {
    content: $rh-icon-chrome; 
  }
}
.rh-icon-firefox {
  &:before {
    content: $rh-icon-firefox; 
  }
}
.rh-icon-IE {
  &:before {
    content: $rh-icon-IE; 
  }
}
.rh-icon-edge {
  &:before {
    content: $rh-icon-edge; 
  }
}
.rh-icon-safari {
  &:before {
    content: $rh-icon-safari; 
  }
}
.rh-icon-opera {
  &:before {
    content: $rh-icon-opera; 
  }
}
.rh-icon-file-pdf {
  &:before {
    content: $rh-icon-file-pdf; 
  }
}
.rh-icon-file-openoffice {
  &:before {
    content: $rh-icon-file-openoffice; 
  }
}
.rh-icon-file-word {
  &:before {
    content: $rh-icon-file-word; 
  }
}
.rh-icon-file-excel {
  &:before {
    content: $rh-icon-file-excel; 
  }
}
.rh-icon-libreoffice {
  &:before {
    content: $rh-icon-libreoffice; 
  }
}
.rh-icon-html-five {
  &:before {
    content: $rh-icon-html-five; 
  }
}
.rh-icon-html-five2 {
  &:before {
    content: $rh-icon-html-five2; 
  }
}
.rh-icon-css3 {
  &:before {
    content: $rh-icon-css3; 
  }
}
.rh-icon-git {
  &:before {
    content: $rh-icon-git; 
  }
}
.rh-icon-codepen {
  &:before {
    content: $rh-icon-codepen; 
  }
}
.rh-icon-svg {
  &:before {
    content: $rh-icon-svg; 
  }
}
.rh-icon-IcoMoon {
  &:before {
    content: $rh-icon-IcoMoon; 
  }
}

