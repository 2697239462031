*,
*:after,
*:before {
	box-sizing: border-box;
}

html {
	// 1rem = 10px, 1em = 10px from root
	font-size: 62.5%;

	@include media-ie() {
		font-size: 10px;
		font-size: 10px;
	}
}

body {
	@include font-main();
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

@include color-schemes('color');

a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}

h1, h2, h3, h4, h5, h6, p, pre, blockquote, table, ol, ul, form {
	margin-bottom: $base-distance;
}

h1,
.h1 {
	position: relative;

	@include font-h1();
}

h2,
.h2 {
	@include font-h2();
}

h3,
.h3 {
	@include font-h3();
}

h4,
.h4 {
	@include font-h4();
}

h5,
.h5 {
	@include font-h5();
}

h6,
.h6 {
	@include font-h6();
}

q {
    quotes: "«" "»" "‹" "›";
}

input:focus,
textarea:focus,
select:focus {
	outline: 1px solid $focus-outline-color;
	outline-offset: -1px;
	transition: outline 0.4s;
}

@include selection();

hr {
	margin-top: $base-distance * 2;
	margin-bottom: $base-distance * 2;
	opacity: .6;

	@include color-schemes('border-color');
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
	background-position: center center;
}

input,
select,
button,
textarea {
	@include input-base();
}

label {
	display: block;
}

[type="button"],
[type="submit"],
button {
	@include color-schemes('background-color', 'color');

    cursor: pointer;
    transition: color .4s, background .8s;

	&:hover{
		@include color-schemes('color', 'background-color');

		transition-duration: .2s, .4s;
	}
}

table {
	width: 100%;

	a {
		color: inherit;
	}
}

caption,
th {
	font-weight: inherit;
}

caption {
	margin-bottom: $base-distance / 2;

	@include font-table-title;
}

th,
td {
	padding: .6rem 2.4rem .8rem .2rem;
}

tr {
	border-width: 0 0 1px;
	border-style: solid;

	@include color-schemes('border-color');

	&,
	a {
		transition: none;
	}

	tbody &:hover,
	tbody &.click,
	tbody &.hover {
		@include color-schemes('background-color', 'color');

		&,
		a {
			transition-duration: 0s;
		}
	}
}

thead tr {
	border-bottom-width: 2px;
}

.main-wrapper{
	min-height: 100vh;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
}

.main-header,
.main-footer {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
}

.main-container{
	-webkit-box-flex: 1;
	    -ms-flex: 1 0 auto;
	        flex: 1 0 auto;
    -webkit-flex: 1 0 auto;
}


.base-width {
	width: 98%;
	width: calc(100% - 2 * #{$content-margin-side});
	max-width: $max-content-width;
	margin-right: auto;
	margin-left: auto;

	&--full-width{
		width: auto;
		max-width: none;
	}
}

.main .mod_article > * {
	@extend .base-width;

	&.full-width{
		@extend .base-width--full-width;

		&.ce_image {
			img {
				width: 100%;
			}
		}
	}
}

/**
 * Header
 */

.main-header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 100;
	padding-top: 4.4rem;
	padding-bottom: 4rem;
	color: $white;
	background-color: $green;
	transition: padding 1s;

	@include media-tablet() {
		padding-top: 1.4rem;
		padding-bottom: 1.4rem;
	}

	&.has-scrolled {
		padding-top: 1.2rem;
		padding-bottom: 1rem;
	}
}

.header-wrapper {
	position: relative;

	ul {
		margin: 0;
	}

	.logo {
		transition: width 1s;

		.has-scrolled & {
			width: 10rem;
		}
	}

	.logo-path {
		@include color-schemes('fill');
	}

	.menu {
		.mod_navigation {
			text-align: center;

			a,
			strong {
				position: relative;
				z-index: 10;
				white-space: nowrap;
				color: $white;
				transition: color .2s .2s;
			}

			> ul {
				line-height: 1;

				> li {
					display: inline-block;
					position: relative;

					& > a:after,
					& > strong:after {
						content: attr(data-name);
						display: block;
						overflow: hidden;
						position: absolute;
						top: 0;
						right: 0;
						left: 0;
						height: 1.2em;
						color: $white;
						font-size: 1em;
						opacity: 0;
						transition: top, height, margin, opacity, font-size, color, background;
						transition-duration: .8s, .8s, .8s, .8s, .8s, .8s, .4s;
					}

					&:hover,
					&.active,
					&.trail {
						> a,
						> strong {
							&:after {
								top: 100%;
								top: calc(100% + .2em);
								height: 4px;
		// 						font-size: 0em;
								background-color: $white;
								opacity: 1;
								transition-duration: .4s;
							}
						}
					}

					&:not(:last-child) {
						margin-right: 4.4rem;
					}
				}
			}

			ul {
				ul {
					background-color: $green;

					a,
					strong {
						padding: .4rem 1rem;
						min-height: 1.7em;
					}
				}

				a,
				strong {
					display: block;
					padding: 0;
					font-weight: inherit;
				}

				li {
					ul {
						position: absolute;
						top: 100%;
						left: 50%;
						padding-top: 2rem;
						visibility: hidden;
						opacity: 0;
						transform: translate(-50%, -10px);
						transition: opacity .4s, transform .4s, visibility 0s .4s;
					}

					&:hover {
						> ul {
							opacity: 1;
							visibility: visible;
							transform: translate(-50%, 0px);
							transition-delay: 0s, 0s, 0s;
						}
					}
				}
			}
		}
	}
}

/**
 * header area
 */

.header-area {
	position: relative;
	padding-top: 14rem;
	background-color: $green;
// 	animation: fadeInDown 2.6s forwards;

	@include media-tablet() {
		padding-top: 8rem;
	}

	.ce_image {
		img {
			width: 100%;
		}
	}


	.link-boxes {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		animation: fadeInDown 3.2s forwards;

		@extend .base-width;

		@include media-tablet() {
			position: relative;
			margin-top: -4rem;
			margin-top: -10vmin;
		}

		> ul {
			-webkit-box-pack: start;
			-ms-flex-pack: start;
			justify-content: flex-start;

			> * {
				width: 50%;

				@include media-tablet() {
					width: 100%;
				}
			}
		}
	}

	.dlh_googlemap {
		padding-bottom: 30% !important;
	}
}

/**
 * Main content
 */

.main {
	.mod_article {
		> .ce_image {
			img {
				margin-top: $base-distance * 2;
			}
		}
	}
}
/**
 * Footer
 */

.main-footer {
	@include color-schemes("background-color", "color");

	position: relative;
	margin-top: 11rem;
	padding-top: 4rem;
	padding-bottom: $base-distance;

	a {
		color: inherit;
	}
}

.footer-wrapper {
}

.footer-area {
	h5,
	h6 {
		margin-top: 0;
	}

	.mod_navigation,
	.mod_customnav {
		a,
		strong {
			text-decoration: underline;
			font-weight: inherit;
		}
	}
}
