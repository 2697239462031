/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    link-box-tile module
    Authors     mac signer

*****************************************************************************/

/*
.link-box-tile

Styleguide classes.link-box-tile
*/

.link-box-tile {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: $link-box-distance;

	@include font-link-box();

	@include color-schemes('background-color', 'color');

	> * {
		flex: 0 0 auto;
	}

	> :nth-last-child(2):not(:first-child) {
		flex-grow: 1;
	}

	&__title {
		@include font-link-box-title();
	}

	&__cover-link {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: 0 !important;
	}
}
