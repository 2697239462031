$icomoon-font-family: "Ringgishalde" !default;
$icomoon-font-path: "fonts" !default;

$rh-icon-home: "\e900";
$rh-icon-home2: "\e901";
$rh-icon-home3: "\e902";
$rh-icon-office: "\e903";
$rh-icon-newspaper: "\e904";
$rh-icon-pencil: "\e905";
$rh-icon-pencil2: "\e906";
$rh-icon-quill: "\e907";
$rh-icon-pen: "\e908";
$rh-icon-blog: "\e909";
$rh-icon-eyedropper: "\e90a";
$rh-icon-droplet: "\e90b";
$rh-icon-paint-format: "\e90c";
$rh-icon-image: "\e90d";
$rh-icon-images: "\e90e";
$rh-icon-camera: "\e90f";
$rh-icon-headphones: "\e910";
$rh-icon-music: "\e911";
$rh-icon-play: "\e912";
$rh-icon-film: "\e913";
$rh-icon-video-camera: "\e914";
$rh-icon-dice: "\e915";
$rh-icon-pacman: "\e916";
$rh-icon-spades: "\e917";
$rh-icon-clubs: "\e918";
$rh-icon-diamonds: "\e919";
$rh-icon-bullhorn: "\e91a";
$rh-icon-connection: "\e91b";
$rh-icon-podcast: "\e91c";
$rh-icon-feed: "\e91d";
$rh-icon-mic: "\e91e";
$rh-icon-book: "\e91f";
$rh-icon-books: "\e920";
$rh-icon-library: "\e921";
$rh-icon-file-text: "\e922";
$rh-icon-profile: "\e923";
$rh-icon-file-empty: "\e924";
$rh-icon-files-empty: "\e925";
$rh-icon-file-text2: "\e926";
$rh-icon-file-picture: "\e927";
$rh-icon-file-music: "\e928";
$rh-icon-file-play: "\e929";
$rh-icon-file-video: "\e92a";
$rh-icon-file-zip: "\e92b";
$rh-icon-copy: "\e92c";
$rh-icon-paste: "\e92d";
$rh-icon-stack: "\e92e";
$rh-icon-folder: "\e92f";
$rh-icon-folder-open: "\e930";
$rh-icon-folder-plus: "\e931";
$rh-icon-folder-minus: "\e932";
$rh-icon-folder-download: "\e933";
$rh-icon-folder-upload: "\e934";
$rh-icon-price-tag: "\e935";
$rh-icon-price-tags: "\e936";
$rh-icon-barcode: "\e937";
$rh-icon-qrcode: "\e938";
$rh-icon-ticket: "\e939";
$rh-icon-cart: "\e93a";
$rh-icon-coin-dollar: "\e93b";
$rh-icon-coin-euro: "\e93c";
$rh-icon-coin-pound: "\e93d";
$rh-icon-coin-yen: "\e93e";
$rh-icon-credit-card: "\e93f";
$rh-icon-calculator: "\e940";
$rh-icon-lifebuoy: "\e941";
$rh-icon-phone: "\e942";
$rh-icon-phone-hang-up: "\e943";
$rh-icon-address-book: "\e944";
$rh-icon-envelop: "\e945";
$rh-icon-pushpin: "\e946";
$rh-icon-location: "\e947";
$rh-icon-location2: "\e948";
$rh-icon-compass: "\e949";
$rh-icon-compass2: "\e94a";
$rh-icon-map: "\e94b";
$rh-icon-map2: "\e94c";
$rh-icon-history: "\e94d";
$rh-icon-clock: "\e94e";
$rh-icon-clock2: "\e94f";
$rh-icon-alarm: "\e950";
$rh-icon-bell: "\e951";
$rh-icon-stopwatch: "\e952";
$rh-icon-calendar: "\e953";
$rh-icon-printer: "\e954";
$rh-icon-keyboard: "\e955";
$rh-icon-display: "\e956";
$rh-icon-laptop: "\e957";
$rh-icon-mobile: "\e958";
$rh-icon-mobile2: "\e959";
$rh-icon-tablet: "\e95a";
$rh-icon-tv: "\e95b";
$rh-icon-drawer: "\e95c";
$rh-icon-drawer2: "\e95d";
$rh-icon-box-add: "\e95e";
$rh-icon-box-remove: "\e95f";
$rh-icon-download: "\e960";
$rh-icon-upload: "\e961";
$rh-icon-floppy-disk: "\e962";
$rh-icon-drive: "\e963";
$rh-icon-database: "\e964";
$rh-icon-undo: "\e965";
$rh-icon-redo: "\e966";
$rh-icon-undo2: "\e967";
$rh-icon-redo2: "\e968";
$rh-icon-forward: "\e969";
$rh-icon-reply: "\e96a";
$rh-icon-bubble: "\e96b";
$rh-icon-bubbles: "\e96c";
$rh-icon-bubbles2: "\e96d";
$rh-icon-bubble2: "\e96e";
$rh-icon-bubbles3: "\e96f";
$rh-icon-bubbles4: "\e970";
$rh-icon-user: "\e971";
$rh-icon-users: "\e972";
$rh-icon-user-plus: "\e973";
$rh-icon-user-minus: "\e974";
$rh-icon-user-check: "\e975";
$rh-icon-user-tie: "\e976";
$rh-icon-quotes-left: "\e977";
$rh-icon-quotes-right: "\e978";
$rh-icon-hour-glass: "\e979";
$rh-icon-spinner: "\e97a";
$rh-icon-spinner2: "\e97b";
$rh-icon-spinner3: "\e97c";
$rh-icon-spinner4: "\e97d";
$rh-icon-spinner5: "\e97e";
$rh-icon-spinner6: "\e97f";
$rh-icon-spinner7: "\e980";
$rh-icon-spinner8: "\e981";
$rh-icon-spinner9: "\e982";
$rh-icon-spinner10: "\e983";
$rh-icon-spinner11: "\e984";
$rh-icon-binoculars: "\e985";
$rh-icon-search: "\e986";
$rh-icon-zoom-in: "\e987";
$rh-icon-zoom-out: "\e988";
$rh-icon-enlarge: "\e989";
$rh-icon-shrink: "\e98a";
$rh-icon-enlarge2: "\e98b";
$rh-icon-shrink2: "\e98c";
$rh-icon-key: "\e98d";
$rh-icon-key2: "\e98e";
$rh-icon-lock: "\e98f";
$rh-icon-unlocked: "\e990";
$rh-icon-wrench: "\e991";
$rh-icon-equalizer: "\e992";
$rh-icon-equalizer2: "\e993";
$rh-icon-cog: "\e994";
$rh-icon-cogs: "\e995";
$rh-icon-hammer: "\e996";
$rh-icon-magic-wand: "\e997";
$rh-icon-aid-kit: "\e998";
$rh-icon-bug: "\e999";
$rh-icon-pie-chart: "\e99a";
$rh-icon-stats-dots: "\e99b";
$rh-icon-stats-bars: "\e99c";
$rh-icon-stats-bars2: "\e99d";
$rh-icon-trophy: "\e99e";
$rh-icon-gift: "\e99f";
$rh-icon-glass: "\e9a0";
$rh-icon-glass2: "\e9a1";
$rh-icon-mug: "\e9a2";
$rh-icon-spoon-knife: "\e9a3";
$rh-icon-leaf: "\e9a4";
$rh-icon-rocket: "\e9a5";
$rh-icon-meter: "\e9a6";
$rh-icon-meter2: "\e9a7";
$rh-icon-hammer2: "\e9a8";
$rh-icon-fire: "\e9a9";
$rh-icon-lab: "\e9aa";
$rh-icon-magnet: "\e9ab";
$rh-icon-bin: "\e9ac";
$rh-icon-bin2: "\e9ad";
$rh-icon-briefcase: "\e9ae";
$rh-icon-airplane: "\e9af";
$rh-icon-truck: "\e9b0";
$rh-icon-accessibility: "\e9b2";
$rh-icon-target: "\e9b3";
$rh-icon-shield: "\e9b4";
$rh-icon-power: "\e9b5";
$rh-icon-switch: "\e9b6";
$rh-icon-power-cord: "\e9b7";
$rh-icon-clipboard: "\e9b8";
$rh-icon-list-numbered: "\e9b9";
$rh-icon-list: "\e9ba";
$rh-icon-list2: "\e9bb";
$rh-icon-tree: "\e9bc";
$rh-icon-menu: "\e9bd";
$rh-icon-menu2: "\e9be";
$rh-icon-menu3: "\e9bf";
$rh-icon-menu4: "\e9c0";
$rh-icon-cloud: "\e9c1";
$rh-icon-cloud-download: "\e9c2";
$rh-icon-cloud-upload: "\e9c3";
$rh-icon-cloud-check: "\e9c4";
$rh-icon-download2: "\e9c5";
$rh-icon-upload2: "\e9c6";
$rh-icon-download3: "\e9c7";
$rh-icon-upload3: "\e9c8";
$rh-icon-sphere: "\e9c9";
$rh-icon-earth: "\e9ca";
$rh-icon-link: "\e9cb";
$rh-icon-flag: "\e9cc";
$rh-icon-attachment: "\e9cd";
$rh-icon-eye: "\e9ce";
$rh-icon-eye-plus: "\e9cf";
$rh-icon-eye-minus: "\e9d0";
$rh-icon-eye-blocked: "\e9d1";
$rh-icon-bookmark: "\e9d2";
$rh-icon-bookmarks: "\e9d3";
$rh-icon-sun: "\e9d4";
$rh-icon-contrast: "\e9d5";
$rh-icon-brightness-contrast: "\e9d6";
$rh-icon-star-empty: "\e9d7";
$rh-icon-star-half: "\e9d8";
$rh-icon-star-full: "\e9d9";
$rh-icon-heart: "\e9da";
$rh-icon-heart-broken: "\e9db";
$rh-icon-man: "\e9dc";
$rh-icon-woman: "\e9dd";
$rh-icon-man-woman: "\e9de";
$rh-icon-happy: "\e9df";
$rh-icon-happy2: "\e9e0";
$rh-icon-smile: "\e9e1";
$rh-icon-smile2: "\e9e2";
$rh-icon-tongue: "\e9e3";
$rh-icon-tongue2: "\e9e4";
$rh-icon-sad: "\e9e5";
$rh-icon-sad2: "\e9e6";
$rh-icon-wink: "\e9e7";
$rh-icon-wink2: "\e9e8";
$rh-icon-grin: "\e9e9";
$rh-icon-grin2: "\e9ea";
$rh-icon-cool: "\e9eb";
$rh-icon-cool2: "\e9ec";
$rh-icon-angry: "\e9ed";
$rh-icon-angry2: "\e9ee";
$rh-icon-evil: "\e9ef";
$rh-icon-evil2: "\e9f0";
$rh-icon-shocked: "\e9f1";
$rh-icon-shocked2: "\e9f2";
$rh-icon-baffled: "\e9f3";
$rh-icon-baffled2: "\e9f4";
$rh-icon-confused: "\e9f5";
$rh-icon-confused2: "\e9f6";
$rh-icon-neutral: "\e9f7";
$rh-icon-neutral2: "\e9f8";
$rh-icon-hipster: "\e9f9";
$rh-icon-hipster2: "\e9fa";
$rh-icon-wondering: "\e9fb";
$rh-icon-wondering2: "\e9fc";
$rh-icon-sleepy: "\e9fd";
$rh-icon-sleepy2: "\e9fe";
$rh-icon-frustrated: "\e9ff";
$rh-icon-frustrated2: "\ea00";
$rh-icon-crying: "\ea01";
$rh-icon-crying2: "\ea02";
$rh-icon-point-up: "\ea03";
$rh-icon-point-right: "\ea04";
$rh-icon-point-down: "\ea05";
$rh-icon-point-left: "\ea06";
$rh-icon-warning: "\ea07";
$rh-icon-notification: "\ea08";
$rh-icon-question: "\ea09";
$rh-icon-plus: "\ea0a";
$rh-icon-minus: "\ea0b";
$rh-icon-info: "\ea0c";
$rh-icon-cancel-circle: "\ea0d";
$rh-icon-blocked: "\ea0e";
$rh-icon-cross: "\ea0f";
$rh-icon-checkmark: "\ea10";
$rh-icon-checkmark2: "\ea11";
$rh-icon-spell-check: "\ea12";
$rh-icon-enter: "\ea13";
$rh-icon-exit: "\ea14";
$rh-icon-play2: "\ea15";
$rh-icon-pause: "\ea16";
$rh-icon-stop: "\ea17";
$rh-icon-previous: "\ea18";
$rh-icon-next: "\ea19";
$rh-icon-backward: "\ea1a";
$rh-icon-forward2: "\ea1b";
$rh-icon-play3: "\ea1c";
$rh-icon-pause2: "\ea1d";
$rh-icon-stop2: "\ea1e";
$rh-icon-backward2: "\ea1f";
$rh-icon-forward3: "\ea20";
$rh-icon-first: "\ea21";
$rh-icon-last: "\ea22";
$rh-icon-previous2: "\ea23";
$rh-icon-next2: "\ea24";
$rh-icon-eject: "\ea25";
$rh-icon-volume-high: "\ea26";
$rh-icon-volume-medium: "\ea27";
$rh-icon-volume-low: "\ea28";
$rh-icon-volume-mute: "\ea29";
$rh-icon-volume-mute2: "\ea2a";
$rh-icon-volume-increase: "\ea2b";
$rh-icon-volume-decrease: "\ea2c";
$rh-icon-loop: "\ea2d";
$rh-icon-loop2: "\ea2e";
$rh-icon-infinite: "\ea2f";
$rh-icon-shuffle: "\ea30";
$rh-icon-arrow-up-left: "\ea31";
$rh-icon-arrow-up: "\ea32";
$rh-icon-arrow-up-right: "\ea33";
$rh-icon-arrow-right: "\ea34";
$rh-icon-arrow-down-right: "\ea35";
$rh-icon-arrow-down: "\ea36";
$rh-icon-arrow-down-left: "\ea37";
$rh-icon-arrow-left: "\ea38";
$rh-icon-arrow-up-left2: "\ea39";
$rh-icon-arrow-up2: "\ea3a";
$rh-icon-arrow-up-right2: "\ea3b";
$rh-icon-arrow-right2: "\ea3c";
$rh-icon-arrow-down-right2: "\ea3d";
$rh-icon-arrow-down2: "\ea3e";
$rh-icon-arrow-down-left2: "\ea3f";
$rh-icon-arrow-left2: "\ea40";
$rh-icon-circle-up: "\ea41";
$rh-icon-circle-right: "\ea42";
$rh-icon-circle-down: "\ea43";
$rh-icon-circle-left: "\ea44";
$rh-icon-tab: "\ea45";
$rh-icon-move-up: "\ea46";
$rh-icon-move-down: "\ea47";
$rh-icon-sort-alpha-asc: "\ea48";
$rh-icon-sort-alpha-desc: "\ea49";
$rh-icon-sort-numeric-asc: "\ea4a";
$rh-icon-sort-numberic-desc: "\ea4b";
$rh-icon-sort-amount-asc: "\ea4c";
$rh-icon-sort-amount-desc: "\ea4d";
$rh-icon-command: "\ea4e";
$rh-icon-shift: "\ea4f";
$rh-icon-ctrl: "\ea50";
$rh-icon-opt: "\ea51";
$rh-icon-checkbox-checked: "\ea52";
$rh-icon-checkbox-unchecked: "\ea53";
$rh-icon-radio-checked: "\ea54";
$rh-icon-radio-checked2: "\ea55";
$rh-icon-radio-unchecked: "\ea56";
$rh-icon-crop: "\ea57";
$rh-icon-make-group: "\ea58";
$rh-icon-ungroup: "\ea59";
$rh-icon-scissors: "\ea5a";
$rh-icon-filter: "\ea5b";
$rh-icon-font: "\ea5c";
$rh-icon-ligature: "\ea5d";
$rh-icon-ligature2: "\ea5e";
$rh-icon-text-height: "\ea5f";
$rh-icon-text-width: "\ea60";
$rh-icon-font-size: "\ea61";
$rh-icon-bold: "\ea62";
$rh-icon-underline: "\ea63";
$rh-icon-italic: "\ea64";
$rh-icon-strikethrough: "\ea65";
$rh-icon-omega: "\ea66";
$rh-icon-sigma: "\ea67";
$rh-icon-page-break: "\ea68";
$rh-icon-superscript: "\ea69";
$rh-icon-subscript: "\ea6a";
$rh-icon-superscript2: "\ea6b";
$rh-icon-subscript2: "\ea6c";
$rh-icon-text-color: "\ea6d";
$rh-icon-pagebreak: "\ea6e";
$rh-icon-clear-formatting: "\ea6f";
$rh-icon-table: "\ea70";
$rh-icon-table2: "\ea71";
$rh-icon-insert-template: "\ea72";
$rh-icon-pilcrow: "\ea73";
$rh-icon-ltr: "\ea74";
$rh-icon-rtl: "\ea75";
$rh-icon-section: "\ea76";
$rh-icon-paragraph-left: "\ea77";
$rh-icon-paragraph-center: "\ea78";
$rh-icon-paragraph-right: "\ea79";
$rh-icon-paragraph-justify: "\ea7a";
$rh-icon-indent-increase: "\ea7b";
$rh-icon-indent-decrease: "\ea7c";
$rh-icon-share: "\ea7d";
$rh-icon-new-tab: "\ea7e";
$rh-icon-embed: "\ea7f";
$rh-icon-embed2: "\ea80";
$rh-icon-terminal: "\ea81";
$rh-icon-share2: "\ea82";
$rh-icon-mail: "\ea83";
$rh-icon-mail2: "\ea84";
$rh-icon-mail3: "\ea85";
$rh-icon-mail4: "\ea86";
$rh-icon-amazon: "\ea87";
$rh-icon-google: "\ea88";
$rh-icon-google2: "\ea89";
$rh-icon-google3: "\ea8a";
$rh-icon-google-plus: "\ea8b";
$rh-icon-google-plus2: "\ea8c";
$rh-icon-google-plus3: "\ea8d";
$rh-icon-hangouts: "\ea8e";
$rh-icon-google-drive: "\ea8f";
$rh-icon-facebook: "\ea90";
$rh-icon-facebook2: "\ea91";
$rh-icon-instagram: "\ea92";
$rh-icon-whatsapp: "\ea93";
$rh-icon-spotify: "\ea94";
$rh-icon-telegram: "\ea95";
$rh-icon-twitter: "\ea96";
$rh-icon-vine: "\ea97";
$rh-icon-vk: "\ea98";
$rh-icon-renren: "\ea99";
$rh-icon-sina-weibo: "\ea9a";
$rh-icon-rss: "\ea9b";
$rh-icon-rss2: "\ea9c";
$rh-icon-youtube: "\ea9d";
$rh-icon-youtube2: "\ea9e";
$rh-icon-twitch: "\ea9f";
$rh-icon-vimeo: "\eaa0";
$rh-icon-vimeo2: "\eaa1";
$rh-icon-lanyrd: "\eaa2";
$rh-icon-flickr: "\eaa3";
$rh-icon-flickr2: "\eaa4";
$rh-icon-flickr3: "\eaa5";
$rh-icon-flickr4: "\eaa6";
$rh-icon-dribbble: "\eaa7";
$rh-icon-behance: "\eaa8";
$rh-icon-behance2: "\eaa9";
$rh-icon-deviantart: "\eaaa";
$rh-icon-500px: "\eaab";
$rh-icon-steam: "\eaac";
$rh-icon-steam2: "\eaad";
$rh-icon-dropbox: "\eaae";
$rh-icon-onedrive: "\eaaf";
$rh-icon-github: "\eab0";
$rh-icon-npm: "\eab1";
$rh-icon-basecamp: "\eab2";
$rh-icon-trello: "\eab3";
$rh-icon-wordpress: "\eab4";
$rh-icon-joomla: "\eab5";
$rh-icon-ello: "\eab6";
$rh-icon-blogger: "\eab7";
$rh-icon-blogger2: "\eab8";
$rh-icon-tumblr: "\eab9";
$rh-icon-tumblr2: "\eaba";
$rh-icon-yahoo: "\eabb";
$rh-icon-yahoo2: "\eabc";
$rh-icon-tux: "\eabd";
$rh-icon-appleinc: "\eabe";
$rh-icon-finder: "\eabf";
$rh-icon-android: "\eac0";
$rh-icon-windows: "\eac1";
$rh-icon-windows8: "\eac2";
$rh-icon-soundcloud: "\eac3";
$rh-icon-soundcloud2: "\eac4";
$rh-icon-skype: "\eac5";
$rh-icon-reddit: "\eac6";
$rh-icon-hackernews: "\eac7";
$rh-icon-wikipedia: "\eac8";
$rh-icon-linkedin: "\eac9";
$rh-icon-linkedin2: "\eaca";
$rh-icon-lastfm: "\eacb";
$rh-icon-lastfm2: "\eacc";
$rh-icon-delicious: "\eacd";
$rh-icon-stumbleupon: "\eace";
$rh-icon-stumbleupon2: "\eacf";
$rh-icon-stackoverflow: "\ead0";
$rh-icon-pinterest: "\ead1";
$rh-icon-pinterest2: "\ead2";
$rh-icon-xing: "\ead3";
$rh-icon-xing2: "\ead4";
$rh-icon-flattr: "\ead5";
$rh-icon-foursquare: "\ead6";
$rh-icon-yelp: "\ead7";
$rh-icon-paypal: "\ead8";
$rh-icon-chrome: "\ead9";
$rh-icon-firefox: "\eada";
$rh-icon-IE: "\eadb";
$rh-icon-edge: "\eadc";
$rh-icon-safari: "\eadd";
$rh-icon-opera: "\eade";
$rh-icon-file-pdf: "\eadf";
$rh-icon-file-openoffice: "\eae0";
$rh-icon-file-word: "\eae1";
$rh-icon-file-excel: "\eae2";
$rh-icon-libreoffice: "\eae3";
$rh-icon-html-five: "\eae4";
$rh-icon-html-five2: "\eae5";
$rh-icon-css3: "\eae6";
$rh-icon-git: "\eae7";
$rh-icon-codepen: "\eae8";
$rh-icon-svg: "\eae9";
$rh-icon-IcoMoon: "\eaea";

