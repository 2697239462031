/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    mixin media helper
    Authors

*****************************************************************************/

@mixin media-helper() {
	@content;

	&-desktop-small {
		@include media-desktop-small() {
			@content;
		}
	}

	&-tablet {
		@include media-tablet() {
			@content;
		}
	}

	&-mobile {
		@include media-mobile() {
			@content;
		}
	}
}
