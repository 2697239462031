/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    mixin selection
    Authors

*****************************************************************************/


@mixin selection-base($color, $bg-color) {
	color: $color;
	background: $bg-color;
	text-shadow: none;
}

@mixin selection($color: $selection-color, $bg-color: $selection-bg-color) {
	::-moz-selection {
		@include selection-base($color, $bg-color);
	}

	::selection {
		@include selection-base($color, $bg-color);
	}
}
