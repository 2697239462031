/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    ce_text text-container module
    Authors     mac signer

*****************************************************************************/

.ce_text,
.text-container {
	ol,
	ul {
		display: table;
		list-style: none;
		width: 100%;
	}

	li {
		display: table-row;

		&:before {
			content: "–";
			display: table-cell;
			width: 1px;
			padding-right: .3em;
		}

		&:after {
			content: "";
			display: block;
			margin-bottom: .4em;
		}

		ul,
		ol,
		li:after {
			margin-bottom: 0;
		}
	}

	ol {
		counter-reset: list-count;

		li {
			&:before {
				content: counter(list-count) ".";
				counter-increment: list-count;
				text-align: right;
			}
		}
	}

	a {
		text-decoration: underline;
		transition: color .3s;

		&:hover {
			text-decoration: none;
		}
	}
}
