/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    font mixins
    Authors     mac signer
    Datum       10.05.18

*****************************************************************************/

@mixin font-main() {
	font-family: $font-family-main;
	font-size: $font-size-100;
	font-weight: $font-weight-regular;
	letter-spacing: 0;
	line-height: 1.3;

	@include media-mobile() {
		font-size: $font-size-90;
	}
}

@mixin font-h1() {
	font-family: $font-family-title;
	font-size: $font-size-120;
	font-weight: $font-weight-bold;
	letter-spacing: 0;
	line-height: 1;

	@include media-mobile() {
		font-size: $font-size-120 * 0.7;
	}
}

@mixin font-h2() {
	font-family: $font-family-title;
	font-size: $font-size-110;
	font-weight: $font-weight-regular;
	letter-spacing: 0;
	line-height: 1;

	@include media-mobile() {
		font-size: $font-size-110 * 0.7;
	}
}

@mixin font-h3() {
	font-family: $font-family-title;
	font-size: $font-size-100;
	font-weight: $font-weight-bold;
	letter-spacing: 0;
}

@mixin font-h4() {
	font-family: $font-family-title;
	font-weight: $font-weight-bold;
}

@mixin font-h5() {
	font-family: $font-family-title;
	font-weight: $font-weight-bold;
}

@mixin font-h6() {
	font-family: $font-family-title;
	font-weight: $font-weight-bold;
}

@mixin font-link-box() {
	font-size: $font-size-90;
	font-weight: $font-weight-regular;
	letter-spacing: 0;
}

@mixin font-link-box-title() {
	@include font-h2();
}

@mixin font-table-title() {
	font-family: $font-family-main;
	font-size: inherit;
	font-weight: $font-weight-bold;
	letter-spacing: 0;
}

@mixin font-apartment-caption() {
	font-size: $font-size-70;
	font-weight: $font-weight-regular;
	letter-spacing: 0;
}

@mixin font-footer() {
	font-size: $font-size-80;
	font-weight: $font-weight-regular;
	letter-spacing: 0;
	line-height: 1.1;

	@include media-mobile() {
		font-size: $font-size-70;
	}
}

@mixin font-footer-title() {
	font-family: inherit;
	font-size: inherit;
	font-weight: $font-weight-bold;
}

