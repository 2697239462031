/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    description-table module
    Authors     mac signer

*****************************************************************************/

/*
.description-table

Styleguide classes.description-table
*/

.description-table {
	margin-top: -.6rem;

	td:first-child {
		white-space: nowrap;
	}
}
