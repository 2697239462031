/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    featherlight module
    Authors

*****************************************************************************/

.featherlight {
	&:last-child {
		background-color: rgba($green, .8);;
	}

	& &-content {
		margin-right: 0;
		margin-left: 0;
		border-width: 0;
		padding: 0;
	}

	&--gallery {
		img {
			width: auto !important;
			height: auto !important;
			max-width: 90vw;
			max-width: calc(100vw - 70px);
			max-height: 90vw;
			max-height: calc(100vh - 60px);
		}
	}

	&-previous,
	&-next {
		top: 0;
		bottom: 0;
	}

	&-previous {
		left: 0;
	}

	&-next {
		right: 0;
	}

	& &-close-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		height: auto;
		padding: .4em;
		line-height: 1;
		text-align: center;
		color: $white;
		background-color: $green;
	}
}
