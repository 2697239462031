/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion    mixin input
    Authors

*****************************************************************************/

@mixin input-base() {
	display: block;
	width: 100%;
	margin-bottom: $base-distance;
	padding: .4rem;
	border-width: 1px;
	border-style: solid;
	border-radius: 0px;
	transition: color .4s, background .4s;

	@include color-schemes('border-color');
}
